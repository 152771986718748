import { useState, useEffect } from "react";
import TextInput from "../../../components/sales-application/text-input/TextInput";
import BreadCrumb from "../../../components/partner-application/breadcrumb/BreadCrumb";
import BoxInput from "../../../components/partner-application/box-input/BoxInput";
import { PartnerService } from "../../../services/api/partnerService";
import {SalesService} from "../../../services/api/salesService";
import "./style.scss";
import { Toaster } from "../../../utils/utilities/Toaster";
import { useNavigate } from "react-router-dom";

const breadCrumbData = [{ name: "Retailer OnBoarding", path: "/retailer/list" }];
const OnBoardingRetailer = () => {
  const partnerService = new PartnerService();
  const saleService = new SalesService();
  const toaster = new Toaster();
  const navigate = useNavigate();
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [pincodeDetails, setPincodeDetails] = useState("");
  const [issuccess, setIsSuccess] = useState(false);
  const [isPincodeValid, setIsPincodeValid] = useState(false);
  const [form, setForm] = useState({
    WhatsappNumber: "",
    RetailerName: "",
    BusinessName: "",
    BusinessAddress: "",
    pincode: "",
  });

  const validatePincode = (pincode) => {
    saleService
      .getStateandcity(pincode)
      .then((response) => {
        setIsPincodeValid(true);
        setPincodeDetails((prev) => ({
          ...prev,
          state: response?.data?.data?.state,
          district: response?.data?.data?.district,
        }));
      })
      .catch((error) => {
        setIsPincodeValid(false);
        setPincodeDetails("");
        toaster.showhuntingform(true, "Please enter valid pincode.");
      });
  };
  const handleInput = (key, value) => {
      setForm((prevForm) => ({
        ...prevForm,
        [key]: value,
      }));
  };

  const isVerification = () => {
    const mobileRegex = /^[0-9]{10}$/;
    if (
      form["WhatsappNumber"] === "" ||
      form["RetailerName"] === "" ||
      form["BusinessName"] === "" ||
      form["BusinessAddress"] === "" ||
      form["pincode"] === ""
    ) {
      setIsFormSubmitted(true);
      return false;
    } else if(!mobileRegex.test(form['WhatsappNumber'])){
      toaster.showhuntingform(true, "Enter the Correct WhatsApp Mobile Number")
      return false
    }
    return true;
  };

  const submitForm = () => {
    const partnerId = localStorage.getItem('currentPartnerId')
    if (isVerification()) {
      let payload = {
        mobile: form["WhatsappNumber"],
        name: form["RetailerName"],
        business_name: form["BusinessName"],
        business_address: form["BusinessAddress"],
        business_pincode: form["pincode"],
      };
      partnerService
        .submitRetails(payload, partnerId)
        .then((response) => {
          toaster.show(false, response?.data?.message);
          setIsSuccess(true)
          setTimeout(() => {
            navigate(`/retailer/list`);
          }, 2000);
        })
        .catch((error) => {
          toaster.show(true, error?.response?.data?.message);
        });
    }
  };

  return (
    <div className="p-4">
      <div className="d-flex justify-content-between mb-4">
        <div style={{ fontSize: "24px", fontWeight: 700, color: "black" }}>
          <BreadCrumb breadCrumbData={breadCrumbData} />
        </div>
      </div>
      {issuccess ? (<>
        <div className="card">
        <div className="card-body">
          <div className="row mt-4">
            <div className="col-12 card-retailers-title">
            <img
                  className="complete-sucessfully-img"
                  src={
                    "https://arth-banner.s3.ap-south-1.amazonaws.com/imagemale.gif"
                  }
                />
            </div>
            <div className="col-12 card-retailers-title">
             <div style={{fontSize: 14,
    fontWeight: 'bold',
    marginTop: '1.2rem'}}>
             Your Retailer has been successfully Onboarded!
             </div>
            </div>
          </div>
        </div>
      </div>
      </>):(<>
        <div className="card">
        <div className="card-body">
          <div className="row mt-4">
            <div className="col-md-4 col-sm-6 mb-4">
              <TextInput
                heading={"Retailer Name"}
                placeholder={"Retailer Name"}
                isCompulsoryField={true}
                value={form["RetailerName"]}
                supportiveTextRed={
                  form["RetailerName"] === "" &&
                  isFormSubmitted &&
                  "Please enter the Retailer Name."
                }
                handleInput={handleInput}
                identifier={"RetailerName"}
              />
            </div>
            <div className="col-md-4 col-sm-6 mb-4">
              <BoxInput
                heading={"Whatsapp Number"}
                handleInput={handleInput}
                value={form["WhatsappNumber"]}
                preInputText={"+91"}
                inputMode={"numeric"}
                isCompulsoryField={true}
                noOfBox={10}
                placeholder={0}
                showActionButton={false}
                actionButtonContent={"Verify"}
                identifier={"WhatsappNumber"}
                supportiveTextRed={
                  form["WhatsappNumber"] === "" &&
                  isFormSubmitted &&
                  "Please fill Whatsapp Number"
                }
              />
            </div>
            <div className="col-md-4 col-sm-6 mb-4">
              <TextInput
                heading={"Business Name"}
                placeholder={"Business Name"}
                isCompulsoryField={true}
                value={form["BusinessName"]}
                supportiveTextRed={
                  form["BusinessName"] === "" &&
                  isFormSubmitted &&
                  "Please enter the Business Name."
                }
                handleInput={handleInput}
                identifier={"BusinessName"}
              />
            </div>
            <div className="col-md-4 col-sm-6 mb-4">
              <TextInput
                heading={"Business Address"}
                placeholder={"Business Address"}
                isCompulsoryField={true}
                value={form["BusinessAddress"]}
                supportiveTextRed={
                  form["BusinessAddress"] === "" &&
                  isFormSubmitted &&
                  "Please enter the Business Address."
                }
                handleInput={handleInput}
                identifier={"BusinessAddress"}
              />
            </div>
            <div className="col-md-4 col-sm-6 col-xl-4 col-lg-4 mb-4">
              <BoxInput
                heading={"Business Pincode"}
                value={form["pincode"]}
                isCompulsoryField={true}
                inputMode={"numeric"}
                noOfBox={6}
                placeholder={0}
                handleInput={handleInput}
                identifier={"pincode"}
                supportiveTextGreen={
                  pincodeDetails !== "" &&
                  ` ${pincodeDetails["district"]}, ${pincodeDetails["state"]}`
                }
                supportiveTextRed={
                  isFormSubmitted &&
                  form["pincode"] === "" &&
                  "Please enter Business Pincode."
                }
              />
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="row mt-4" style={{ justifyContent: "center" }}>
          <div
            className="col-md-2 col-sm-2 mb-2"
            style={{ textAlign: "center" }}
          >
            <button onClick={() => submitForm()} className="apply-button">
              Submit
            </button>
          </div>
        </div>
      </div>
      </>)}
      
    </div>
  );
};

export default OnBoardingRetailer;
