import React, { useEffect, useState } from 'react';
import { UpdateRequestSVG, IncompleteProfileSVG, RejectSVG, ApprovedSVG, EyeSVG } from '../../../assets/svg';
import './Listing.scss';
import { SalesService } from '../../../services/api/salesService';
import { useNavigate } from 'react-router-dom';
import { Toaster } from '../../../utils/utilities/Toaster';
const statusIcon = {
  true: <ApprovedSVG />,
  false: <RejectSVG />,
  'In progress': <IncompleteProfileSVG />,
  'Re-KYC': <UpdateRequestSVG />,
  'Approved': <ApprovedSVG />
}

const Listing = ({data, handlesubmit, viewData}) => {
   const [isactive, setIsActive] = useState(false);
   const navigate = useNavigate();
   const saleService = new SalesService();
   const toaster = new Toaster();

   const handleCheckboxChange = (value, e) => {
    setIsActive(e.target.checked)
    handlesubmit(value, e.target.checked)
   }
   
  //  const handlesubmit = (id, active) => {
  //    const data ={
  //     "id": id,
  //     "is_active": active
  //    }
  //    saleService.updateHunter(data).then((response) => {
  //     console.log("ffk", response)
  //      if(response.data.status === "success"){
 
  //        toaster.show(true, response?.data?.message);
  //      }
  //     else if(response?.data?.status === "failure"){
  //      toaster.show(true, response?.message);
  //     }
  //    }).catch((error) => {
  //      toaster.show(true, error?.response?.data?.message);
  //    })
  //  }

  const handleOnboardingDate = (stamp) => {
    const creationTimestamp = stamp;
    const date = new Date(creationTimestamp);
    const options = { year: 'numeric', month: 'short', day: '2-digit' };
    const formattedDate = date.toLocaleDateString('en-GB', options);
    return formattedDate;
  }

const handleEdit = (data)  => {
  if(viewData === "Retailer") {
    navigate(`/retailer/invoice/list/${data?.id}`)
  } else {
    localStorage.setItem('editrole', JSON.stringify(data))
  navigate(`/fos/edit/${data?.id}`)
  }
  
}


  return (
    <>
    {viewData === "Retailer" ? (<>
      <div className='card mt-2'>
      <div className="card-body p-2">
        <div className="listing-wrapper d-flex justify-content-between" onClick={()=> handleEdit(data)}>
          <div className="left-side-card" style={{marginLeft:viewData === "Retailer" ? "0px":'0.8rem'}}>
            <div className="partner-names m-2 table-body-title " style={{textAlign:"start", color:"#EE3E80"}} >
              {data?.name}
            </div>
            <div className=" m-2 table-body-title" style={{textAlign:"start"}}>
            {data?.mobile ?? ''}
            </div>
            <div className=" m-2 table-body-title " style={{textAlign:"start"}}>
            Invoice Count - {data?.invoice_count ?? 0}
            </div>
            
          </div>
          <div className="right-side-card mr-4" style={{marginRight:viewData === "Retailer" ? "0px":'0.8rem'}}>
            <div className="profile-info m-2">
              <div className='table-body-title' style={{color:'green'}}>
                Paid- Rs {data?.total_paid_amount?? 0}
              </div>
            </div>
            <div className='profile-info m-2 table-body-title' style={{color:'red'}}>
          Due- Rs {data?.total_due_amount?? 0}
         
          </div>
            <div className=" profile-info m-2 table-body-title">
           <div style={{color: '#5A607F',
    fontWeight: 400,
    fontSize: '12px'}}>Total- Rs {data?.total_invoice_amount}</div> 
          </div>
          
          </div>
          
        </div>
      </div>
    </div>
    </>):(<>
      <div className='card mt-2'>
      <div className="card-body p-2">
        <div className="listing-wrapper d-flex justify-content-between">
          <div className="left-side-card">
            <div className="partner-names m-2 table-body-title " style={{textAlign:"start", color:"#EE3E80"}} onClick={()=> handleEdit(data)}>
              {data?.employee_name}
            </div>
            <div className="partner-names m-2 table-body-name " style={{textAlign:"start"}}>
            {data?.employee ?? ''}
            </div>
            {/* {data?.role[0]?.role && (<>
              <div className="partner-type">
              {data?.role[0]?.role?? ''}
            </div>
            </>)} */}
            
          </div>
          <div className="right-side-card mr-4">
            <div className="profile-info m-2">
              <div>
                {data?.is_active === true && <><span style={{color:"green", textAlign:"start"}} className='labelHeader table-body-name '> Active </span>  </>}
                {data?.is_active === false && <><span className='under-review labelHeader table-body-name ' style={{color:"red", textAlign:"start"}}> InActive</span> | </> }
                {statusIcon[`${data?.is_active}`]}
                <span>
          </span>
              </div>
              <div></div>
            </div>
            <div className="profile-date d-flex justify-content-end labelHeader m-2 table-body-name " style={{textAlign:"start"}}>
              {handleOnboardingDate(data?.create_ts)}
            </div>
            <div className="profile-date d-flex justify-content-end labelHeader m-2 table-body-name " style={{display:"flex", gap: "16%", margin: "7%"}}>
          <input type='checkbox'  checked={data?.is_active} onChange={(e) => handleCheckboxChange(data?.id, e)}/>
          <div className="consent-info">
          Active
          </div>
          </div>
          </div>
        </div>
      </div>
    </div>
    </>)}
    </>
   
  )
}

export default Listing;
