import React, {useRef, useEffect, useState} from 'react';
import './box-input.scss';
import ActionButton from '../button/ActionButton';
import { GreenCheck2 } from '../../../assets/svg';

const BoxInput = (props:any) => {

  const {noOfBox, 
    preInputText,
    disabled,
    value,
    type='text',
    inputMode='text',
    isCompulsoryField,
    heading, 
    placeholder, 
    handleInput, 
    identifier, 
    supportiveTextRed, 
    supportiveTextGreen,
    showActionButton,
    actionButtonContent,
    actionButtonStatus,
    actionSuccess,
    handleActionButton,
    reKycRemarks
  } = props;
  const inputs = useRef<HTMLInputElement[]>([]);
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value)
  },[value])

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (event.key === 'Backspace' && event.currentTarget.value === '' && index > 0) {
      inputs.current[index - 1].focus();
    }
  };

  const handleChange = (event:any, index:any) => {
    const newValue = inputValue.split('');
    newValue[index] = event.target.value;
    setInputValue(newValue.join(''));
    handleInput(identifier, newValue.join(''));

    if (event.target.value !== '' && index < inputs.current.length - 1) {
      inputs.current[index + 1].focus();
    }
  };

  const actionButtonClicked = () => {
    if(actionButtonStatus){
      handleActionButton();
    }
  }

  return (
    <div className=''>
      <div className='partner-heading'>{heading}<span>{isCompulsoryField && '*'}</span></div>
      <div className="input-group" style={{marginTop:"5px"}}>
        <div className={`partner-input-box-container ${supportiveTextRed && 'red-border'}`}>
        {preInputText && <span className="input-group-text rounded-start" id="basic-addon1">{preInputText}</span>}
          {Array.from({ length: noOfBox }, (_, index) => (
            <input 
              key={index}
              disabled={disabled}
              type={type}
              className={`form-control ${index+1 === noOfBox && 'rounded-end'}`}
              maxLength={1}
              ref={(input) =>{if(input)(inputs.current[index] = input)}}
              onKeyDown={(event) => handleKeyDown(event, index)}
              onChange={(event) => handleChange(event, index)}
              value={(value && value[index]) || ''}
              placeholder={placeholder}
              inputMode={inputMode}
              />
          ))}
          {showActionButton && <div onClick={actionButtonClicked} className='action-button-style'>
           {actionSuccess ? <><GreenCheck2 />Verified</> : <ActionButton isActive={actionButtonStatus} buttonContent={actionButtonContent}/>}
          </div>}     
        </div>
        <div className='partner-supportive-text-red'>{supportiveTextRed}</div>
        <div className='partner-supportive-text-green'>{supportiveTextGreen}</div>
        {reKycRemarks?.map((remark:string, index:number) => {
        return (
          <div key={index} className='partner-supportive-text-red'>{remark}</div>
        )
      })}
      </div>
    </div>
  )
}

export default BoxInput;
