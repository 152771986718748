import React, { useState } from "react";
import BusinessPhoto from "../BusinessPhoto/BusinessPhoto";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

function BusinessKYC({ businessData, udyam_expanded }) {
  const gst_in_data = businessData?.gstin_details?.gstin_detail?.gstin_data;

  const gst_sanitized = {};

  if (gst_in_data) {
    Object.keys(gst_in_data).forEach((key) => {
      if (key === "status") {
        return;
      }

      if (typeof gst_in_data[key] === "string") {
        if (!gst_sanitized?.General) {
          gst_sanitized["General"] = {};
        }

        gst_sanitized["General"][convertToProperCase(key)] = gst_in_data[key];
      } else if (Array.isArray(gst_in_data[key])) {
        if (!gst_sanitized?.General) {
          gst_sanitized["General"] = {};
        }
        gst_sanitized["General"][convertToProperCase(key)] = joinStrings(
          gst_in_data[key]
        );
      }
    });

    const additionalOne =
      gst_in_data?.principal_place_of_business_fields
        ?.principal_place_of_business_address;

    if (additionalOne) {
      const formattedAdditional = {};
      Object.keys(additionalOne).forEach((key) => {
        if (additionalOne[key]) {
          formattedAdditional[
            convertToProperCase(key == "dst" ? "District" : key)
          ] = additionalOne[key];
        }
      });
      gst_sanitized[
        convertToProperCase("principal_place_of_business_address")
      ] = formattedAdditional;
    }
  }
  function joinStrings(stringsList) {
    if (!Array.isArray(stringsList)) {
      throw new Error("Input must be an array.");
    }

    return stringsList.join(",");
  }
  function convertToProperCase(text) {
    // Split the text by underscores
    const words = text.split("_");

    // Capitalize the first letter of each word and make the rest lowercase
    const capitalizedWords = words.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });

    // Join the words back with spaces
    return capitalizedWords.join(" ");
  }

  const [expand, setExpand] = useState(false);
  const [gstExpand, setGstExpand] = useState(false);

  const handleExpand = () => {
    expand == false ? setExpand(true) : setExpand(false);
  };

  const handleGSTExpand = () => {
    gstExpand == false ? setGstExpand(true) : setGstExpand(false);
  };

  return (
    <div className="kyc-container">
      <span>KYC DETAILS</span>
      <div className="kyc-content-box">
        <div class="col-md kyc-container">
          <span>Udyam Aadhar Details</span>
          <div>
            <div>
              <div class="col-md">
                <div class="row">
                  <div class="col-md basic-left-body">Udyam Aadhar</div>
                  <div class={`col-md basic-right-body`}>
                    {businessData?.udyam_aadhar_details?.udyam_aadhar}
                  </div>
                </div>
                {businessData?.udyam_aadhar_details?.udyam_aadhar ? (
                  <>
                    <div class="col-md-auto basic-left-body"></div>
                    {businessData?.udyam_aadhar_details?.is_uam_verified !=
                      null && (
                      <div class="col-md basic-right-body pan-name">
                        {businessData?.udyam_aadhar_details?.is_uam_verified ? (
                          "Verified"
                        ) : (
                          <span style={{ color: "red" }}>Not Verified</span>
                        )}
                      </div>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          {udyam_expanded && (
            <div style={{ cursor: "pointer" }} onClick={() => handleExpand()}>
              <span style={{ fontSize: "12px", fontFamily: "Montserrat" }}>
                Udyam Details
              </span>
              <KeyboardArrowDownIcon
                fontSize="12px"
                style={{ transform: expand ? "rotate(180deg)" : "" }}
              />
            </div>
          )}
          {expand &&
            Object.keys(udyam_expanded).map((key) => (
              <div style={{ marginTop: "10px" }}>
                <span
                  style={{
                    fontSize: "14px",
                    fontFamily: "Montserrat",
                    fontWeight: 500,
                    borderColor: "black",
                    borderBottom: "solid",
                    borderWidth: "1px",
                  }}
                >
                  {key}
                </span>

                {Object.keys(udyam_expanded[key]).map((val) => (
                  <div class="row" style={{ marginTop: "5px" }}>
                    <div class="col-md basic-left-body">{val}</div>
                    <div class={`col-md basic-right-body`}>
                      {udyam_expanded[key][val]}
                    </div>
                  </div>
                ))}
              </div>
            ))}
        </div>
        <div class="col-md kyc-container">
          <span>GSTIN Details</span>
          <div style={{ display: "flex" }}>
            <div>
              <div class="col-md-auto">
                <div class="row">
                  <div class="col-md-auto basic-left-body">GSTIN</div>
                  <div class={`col-md basic-right-body`}>
                    {businessData?.gstin_details?.gstin}
                  </div>
                </div>
                {businessData?.gstin_details?.gstin_document && (
                  <div class="row">
                    {/* <BusinessPhoto imageUrl={businessData?.gstin_details?.gstin_document} /> */}
                    <div class="col-md-auto basic-left-body">Document</div>
                    <div class={`col-md basic-right-body`}>
                      <a
                        href={businessData?.gstin_details?.gstin_document}
                        target="_blank"
                        style={{ color: "#EE3E80" }}
                      >
                        Click Here
                      </a>
                    </div>
                  </div>
                )}
                {businessData?.gstin_details?.gstin ? (
                  <>
                    <div class="col-md-auto basic-left-body"></div>
                    {businessData?.gstin_details?.is_gstin_verified != null && (
                      <div class="col-md basic-right-body pan-name">
                        {businessData?.gstin_details?.is_gstin_verified ? (
                          "Verified"
                        ) : (
                          <span style={{ color: "red" }}>Not Verified</span>
                        )}
                      </div>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          {gst_in_data && (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => handleGSTExpand()}
            >
              <span style={{ fontSize: "12px", fontFamily: "Montserrat" }}>
                GSTIN Details
              </span>
              <KeyboardArrowDownIcon
                fontSize="12px"
                style={{ transform: gstExpand ? "rotate(180deg)" : "" }}
              />
            </div>
          )}
          {gstExpand &&
            Object.keys(gst_sanitized).map((key) => (
              <div style={{ marginTop: "10px" }}>
                <span
                  style={{
                    fontSize: "14px",
                    fontFamily: "Montserrat",
                    fontWeight: 500,
                    borderColor: "black",
                    borderBottom: "solid",
                    borderWidth: "1px",
                  }}
                >
                  {key}
                </span>

                {Object.keys(gst_sanitized[key]).map((val) => (
                  <div class="row" style={{ marginTop: "5px" }}>
                    <div class="col-md basic-left-body">{val}</div>
                    <div class={`col-md basic-right-body`}>
                      {gst_sanitized[key][val]}
                    </div>
                  </div>
                ))}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default BusinessKYC;
