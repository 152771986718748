import React from 'react'
import './button.scss';

const ActionButton = (props) => {

  const {isActive=false, buttonContent} = props;

  return (
    <div className={`partner-action-button ${isActive? 'partner-action-button-active' : 'partner-action-button-inactive'}`}>
      {buttonContent}
    </div>
  )
}

export default ActionButton
