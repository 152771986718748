import React from "react";
import { Pie, Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels"; 
ChartJS.register(ArcElement, Tooltip, Legend); // Register basic elements
const Chart = ({ title, chartData }) => {
  let names;
  let values;
ChartJS.register(ChartDataLabels)

  function getRandomColor(length) {
    let letters = "0123456789ABCDEF".split("");
    let result = [];
    for (let i = 0; i < length; i++) {
      let color = "#";
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      result.push(color);
    }
    return result;
  }
   const chartdata ={
    "PAID": Number(chartData?.total_paid_amount),
    "DUE":   Number(chartData?.total_due_amount) 
   }

    names = Object.keys(chartdata);
    values = Object.values(chartdata);
  

  const colors = getRandomColor(names.length);

  const data = {
    labels: names,
    datasets: [
      {
        data: values,
        backgroundColor: ['#1EB386','#FB4B4B'],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        border: '1px solid white',
        titleFont: {
          size: 12,
          weight: 'bold',
          family: "'Arial', sans-serif",
        },
        bodyFont: {
          size: 10,
          family: "'Arial', sans-serif",
        },
        padding: 10,
        cornerRadius: 10,
        callbacks: {
          label: function (tooltipItem) {
            const value = tooltipItem.raw;
            const total = chartData?.total_transaction_amount;
            const percentage = ((value / total) * 100).toFixed(2);

            if (typeof value === 'number') {
              return `${tooltipItem.label}: ${percentage}%`;  // Display percentage in tooltip
            } else {
              return tooltipItem.label;
            }
          },
        },
      },
      datalabels: {
        display: true,
        color: 'white',
        font: {
          size: 12,
          weight: 'bold',
        },
        formatter: (value, context) => {
          const total = chartData?.total_transaction_amount;
          const percentage = ((value / total) * 100).toFixed(2);
          return `${percentage}%`;  // Display percentage in chart
        },
        anchor: 'center',
        align: 'center',
      },
    },
  };

  return (
    <div>
    <div style={{display:"flex", justifyContent:'center', gap:"10px"}}>
        <div style={{display:"flex", justifyContent:"space-between", gap:"4px"}}><div  style={{
                                  color: "#1EB386",
                                  fontWeight: 400,
                                  fontSize: "12px",
                                }}>Paid :</div> <div style={{backgroundColor:"#1EB386", width:"10px", padding:'8px'}}></div></div>
        <div style={{display:"flex", justifyContent:"space-between",   gap:"4px"}}><div  style={{
                                  color: "#FB4B4B",
                                  fontWeight: 400,
                                  fontSize: "12px",
                                
                                }}>Due :</div><div style={{backgroundColor:"#FB4B4B", width:"10px", padding:'8px'}}></div></div>
    </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop:'8px'
        }}
      >
        <div style={{ width: "50%", height: "50%" }}>
          <Doughnut data={data} options={options} />
        </div>
      </div>
    </div>
  );
};

export default Chart;
