import { Modal } from "react-bootstrap";
import "./action-buttons.scss";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Toaster } from "../../../../utils/utilities/Toaster";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";

function ActionButtons({
  status,
  setAction,
  userData,
  productCode,
  amount,
  processingFee,
  emi,
  interestRate,
  tenure,
}) {
  const [holdShow, setHoldShow] = useState(false);
  const navigate = useNavigate();
  const handleHoldClose = () => setHoldShow(false);
  const handleHoldShow = () => setHoldShow(true);

  const [approveShow, setApproveShow] = useState(false);
  const handleApproveClose = () => setApproveShow(false);
  const handleApproveShow = () => setApproveShow(true);

  const [rejectShow, setRejectShow] = useState(false);
  const handleRejectClose = () => setRejectShow(false);
  const handleRejectShow = () => setRejectShow(true);

  const [holdReason, setHoldReason] = useState();
  const [rejectReason, setRejectReason] = useState();

  const [holdDesc, setHoldDesc] = useState("");
  const [rejectDesc, setRejectDesc] = useState("");
  const [selectedReasons, setSelectedReasons] = useState([]);

  useEffect(() => {
    setSelectedReasons([]);
  }, [holdShow, rejectShow]);

  useEffect(() => {
    const valToSet = selectedReasons.map(String).join(",");

    if (rejectShow) {
      setRejectReason(valToSet);
    } else if (holdShow) {
      setHoldReason(valToSet);
    }
  }, [selectedReasons]);

  const takeAction = async (decision) => {
    const toaster = new Toaster();
    const url = new URL(
      process.env.REACT_APP_ICE_BASE_URL +
        "/ice-decision-update/" +
        userData?.basic_details?.id
    );
    try {
      let remark = "Accepted";
      if (decision === "On Hold") {
        if (!holdReason) {
          toaster.show(true, "Please select one option!");
          return;
        }
        remark = holdReason === "Other" ? holdDesc : holdReason;
      } else if (decision === "Rejected") {
        if (!rejectReason) {
          toaster.show(true, "Please select one option!");
          return;
        }
        remark = rejectReason === "Other" ? rejectDesc : rejectReason;
      }

      if (remark === "") {
        toaster.show(true, "Please provide remark!");

        return;
      }

      const request_data = {
        status: decision,
        _remark: remark,
        _open_ts: new Date(),
      };

      if (decision === "Accepted") {
        request_data["product_code"] = productCode;
        request_data["amount"] = amount;
        request_data["logic_product"] = null;
        request_data["paymint_opted"] = false;
        request_data["processing_fees"] = processingFee;
        request_data["emi"] = emi;
        request_data["monthly_interest"] = interestRate;
        request_data["tenure"] = tenure;
      }

      const config = {
        headers: { Authorization: process.env.REACT_APP_ICE_AUTHORIZATION },
      };
      const data = await axios.put(url, request_data, config);

      if (data.status === 202) {
        alert(decision + " action succesfully taken.");
        setAction(decision + userData?.basic_details?.id);
        if (decision === "On Hold") {
          handleHoldClose();
        } else if (decision === "Accepted") {
          handleApproveClose();
        } else {
          handleRejectClose();
        }
        navigate("/ice/customers");
      }
    } catch (error) {
      toaster.show(true, error?.response?.data?.message);
      console.log(error);
    }
  };

  let button_style = {
    backgroundColor: "#FAFBFD",
    width: "120px",
    borderColor: "#D5D5D5",
    cursor: "pointer",
  };
  let hold_style = {
    backgroundColor: "#FAFBFD",
    width: "120px",
    color: "#F1B300",
    borderColor: "#D5D5D5",
    cursor: "pointer",
  };

  let reject_style = {
    backgroundColor: "#FAFBFD",
    width: "120px",
    color: "red",
    borderColor: "#D5D5D5",
  };

  let approve_style = {
    backgroundColor: "#FAFBFD",
    width: "120px",
    color: "#10B759",
    borderColor: "#D5D5D5",
  };

  if (status === "Approve" || status === "Reject") {
    return <></>;
  }


  const rejectOptions = [
    "Borrower age", 
    "Co Borrower age", 
    "Business environment", 
    "Business Vintage", 
    "Business Income", 
    "Business stock", 
    "Business Board", 
    "Business Set-up", 
    "Business Occupation", 
    "Negative Occupation", 
    "Outside 25 kms", 
    "Negative Pincode", 
    "Negative Area", 
    "Both Rented", 
    "Borrower Score", 
    "Bad borrower repayment history", 
    "Borower Bureau Outstanding", 
    "Co Borrower Score", 
    "Co Borrower Repayment", 
    "Other"
]

  const holdOptions = [
    "Business board not captured", 
    "Customer not available in video", 
    "Business environment not captured", 
    "POA", 
    "Co-borrower Age Criteria Not Met", 
    "Co-borrower relationship", 
    "Other"
]


  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    console.log("event - ", value);
    if (value.length > 1 && selectedReasons.includes("Other")) {
      value.splice(value.indexOf("Other"), 1);
    } else if (value.length > 1 && value[value.length - 1] == "Other") {
      setSelectedReasons(["Other"]);
      return;
    }
    setSelectedReasons(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <div style={{ marginLeft: "auto" }}>
      <div className="action-buttons">
        {/* HOLD LOGIC */}
        <Modal show={holdShow} onHide={handleHoldClose}>
          <div style={{ padding: "10px" }}>
            <div className="action-hdr">You Want to Hold?</div>

            <div>Reason</div>
            <div>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={selectedReasons}
                onChange={handleChange}
                renderValue={() => "Selected: " + selectedReasons.length}
                displayEmpty={() => "Selected: " + selectedReasons.length}
                fullWidth
              >
                {holdOptions.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={selectedReasons.includes(name)} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div style={{ marginTop: 10 }}>
              Selected Reason(s): {holdReason ? holdReason : "None"}
            </div>
            {selectedReasons.includes("Other") && (
              <div style={{ marginTop: "10px" }}>
                <div>Describe</div>
                <textarea
                  style={{
                    border: "solid",
                    borderWidth: "1px",
                    borderColor: "#D5D5D5",
                    borderRadius: "10px",
                    height: "100px",
                    width: "100%",
                    padding: "10px",
                  }}
                  placeholder="Describe"
                  onChange={(key) => setHoldDesc(key.target.value)}
                />
              </div>
            )}

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "10px",
                marginTop: "10px",
              }}
            >
              <div onClick={handleHoldClose} className="btn-pointer-class">
                <button
                  type="button"
                  disabled
                  className="btn btn-sm"
                  style={button_style}
                >
                  Cancel
                </button>
              </div>
              <div
                className="btn-pointer-class hold-hover"
                onClick={() => takeAction("On Hold")}
              >
                <button
                  type="button"
                  disabled
                  className="btn btn-sm"
                  style={hold_style}
                >
                  Hold
                </button>
              </div>
            </div>
          </div>
        </Modal>

        {/* APPROVE LOGIC */}
        <Modal show={approveShow} onHide={handleApproveClose}>
          <div style={{ padding: "10px" }}>
            <div className="action-hdr">You Want to Approve?</div>

            <div className="btn-pointer-class">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "10px",
                  marginTop: "10px",
                }}
                onClick={handleApproveClose}
              >
                <button
                  type="button"
                  disabled
                  className="btn btn-sm"
                  style={button_style}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-sm approve-hover"
                  style={approve_style}
                  onClick={() => takeAction("Accepted")}
                >
                  Approve
                </button>
              </div>
            </div>
          </div>
        </Modal>

        {/* REJECT LOGIC */}
        <Modal show={rejectShow} onHide={handleRejectClose}>
          <div style={{ padding: "10px" }}>
            <div className="action-hdr">You Want to Reject?</div>

            <div>Reason</div>
            <div>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={selectedReasons}
                onChange={handleChange}
                renderValue={() => "Selected: " + selectedReasons.length}
                displayEmpty={() => "Selected: " + selectedReasons.length}
                fullWidth
              >
                {rejectOptions.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={selectedReasons.includes(name)} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
              <div style={{ marginTop: 10 }}>
                Selected Reason(s): {rejectReason ? rejectReason : "None"}
              </div>

              {selectedReasons.includes("Other") && (
                <div style={{ marginTop: "10px" }}>
                  <div>Describe</div>
                  <textarea
                    style={{
                      border: "solid",
                      borderWidth: "1px",
                      borderColor: "#D5D5D5",
                      borderRadius: "10px",
                      height: "100px",
                      width: "100%",
                      padding: "10px",
                    }}
                    placeholder="Describe"
                    onChange={(key) => setRejectDesc(key.target.value)}
                  />
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "10px",
                  marginTop: "10px",
                }}
              >
                <div className="btn-pointer-class" onClick={handleRejectClose}>
                  <button
                    type="button"
                    disabled
                    className="btn btn-sm"
                    style={button_style}
                  >
                    Cancel
                  </button>
                </div>
                <div
                  className="btn-pointer-class"
                  onClick={() => takeAction("Rejected")}
                >
                  <button
                    type="button"
                    // disabled
                    className="btn btn-sm reject-hover"
                    style={reject_style}
                  >
                    Reject
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        {status !== "On Hold" && (
          <div
            className="action-style hold-style left-most"
            onClick={handleHoldShow}
          >
            Hold
          </div>
        )}
        <div
          className={`action-style approve-style ${
            status === "On Hold" && "left-most"
          }`}
          onClick={handleApproveShow}
        >
          Approve
        </div>
        <div className="action-style reject-style" onClick={handleRejectShow}>
          Reject
        </div>
      </div>
    </div>
  );
}

export default ActionButtons;
