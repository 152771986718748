import axios, { AxiosResponse } from "axios";
import { headers, partnerHeader, partnerHeaderFromdata } from "../base";
import { id } from "date-fns/locale";

export class SalesService {
  private ARTH_APP_URL: string = process.env.REACT_APP_ARTH_APP_BASE_URL;
  private ARTH_URL: string = process.env.REACT_APP_ARTH_APP_URL;

  authenticateUserCredentials = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(`${this.ARTH_APP_URL}/auth/user/login`, payload);
  };

  logoutUser = (): Promise<AxiosResponse<any>> => {
    return axios.post(
      `${this.ARTH_APP_URL}/auth/user/logout`,
      {},
      { headers: partnerHeader() }
    );
  };

  fetchRoleOfUser = (): Promise<AxiosResponse<any>> => {
    return axios.get(`${this.ARTH_APP_URL}/auth/get_user_roles/`, {
      headers: partnerHeader(),
    });
  };

  getBranchList = (): Promise<AxiosResponse<any>> => {
    return axios.get(`${this.ARTH_APP_URL}/sales_app/v1/branch_list/`, {
      headers: partnerHeader(),
    });
  };
  getListRPM = (): Promise<AxiosResponse<any>> => {
    return axios.get(`${this.ARTH_APP_URL}/sales_app/v1/role_list/`, {
      headers: partnerHeader(),
    });
  };
  getHuntingPartnersList = (): Promise<AxiosResponse<any>> => {
    return axios.get(
      `${this.ARTH_APP_URL}/sales_app/v1/hunting_partners_list/`,
      { headers: partnerHeader() }
    );
  };
  createHunter = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(
      `${this.ARTH_APP_URL}/sales_app/v1/create_hunter/`,
      payload,
      { headers: partnerHeader() }
    );
  };
  updateHunter = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.patch(
      `${this.ARTH_APP_URL}/sales_app/v1/update_hunter/`,
      payload,
      { headers: partnerHeader() }
    );
  };
  getbyhunterListDetails = (id): Promise<AxiosResponse<any>> => {
    let url = `${this.ARTH_APP_URL}/sales_app/v1/get_hunter_list/${id}`;
    // url += `page=${pageNo}`;
    return axios.get(`${url}`, { headers: partnerHeader() });
  };
  getBranchListPQVisit = (): Promise<AxiosResponse<any>> => {
    return axios.get(`${this.ARTH_APP_URL}/sales_app/v1/user/branch_list/`, {
      headers: partnerHeader(),
    });
  };
  getHunterList = (search, filter, pageNo): Promise<AxiosResponse<any>> => {
    // console.log("searchfilterPageno:",search, filter, pageNo);
    let url = `${this.ARTH_APP_URL}/sales_app/v1/get_hunter_list/?`;

    // Check if search is not null or undefined
    if (search) {
      url += `search=${search}&`;
    }

    // Check if active is true
    if (filter) {
      url += `active=${filter}&`;
    }

    // Add the page number parameter
    url += `page=${pageNo}&per_page=${20}`;

    return axios.get(`${url}`, { headers: partnerHeader() });
  };
  //sales_app/v1/update_hunter/'

  requestMobileOtp = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(
      `${this.ARTH_APP_URL}/sales_app/v1/request_otp/`,
      payload,
      { headers: partnerHeader() }
    );
  };

  verifyMobileOtp = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(
      `${this.ARTH_APP_URL}/sales_app/v1/verify_otp/`,
      payload,
      { headers: partnerHeader() }
    );
  };

  getStateandcity = (pincode: string): Promise<AxiosResponse<any>> => {
    return axios.get(
      `${this.ARTH_APP_URL}/hotleadapi/pincode/?pincode=${pincode}`,
      { headers: partnerHeader() }
    );
  };
  getProductQuestionList = (): Promise<AxiosResponse<any>> => {
    return axios.get(
      `${this.ARTH_APP_URL}/sales_app/v1/product_question_list/`,
      { headers: partnerHeader() }
    );
  };
  getPanVerify = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(
      `${this.ARTH_APP_URL}/sales_app/v1/pan_verify/`,
      payload,
      { headers: partnerHeader() }
    );
  };

  createHuntingForm = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(
      `${this.ARTH_APP_URL}/sales_app/v1/create_hunting_form/`,
      payload,
      { headers: partnerHeader() }
    );
  };
  autheticateHuntingForm = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(
      `${this.ARTH_APP_URL}/sales_app/v1/autheticate_lead/`,
      payload,
      { headers: partnerHeader() }
    );
  };
  updateHuntingForm = (payload: any): Promise<AxiosResponse<any>> => {
    // console.log("l",partnerHeaderFromdata(), payload)
    return axios.patch(
      `${this.ARTH_APP_URL}/sales_app/v1/update_in_progress_form/`,
      payload,
      { headers: partnerHeaderFromdata() }
    );
  };
  getDistance = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(
      `${this.ARTH_APP_URL}/sales_app/v1/get_distance/`,
      payload,
      { headers: partnerHeader() }
    );
  };
  updateQCAction = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(`${this.ARTH_APP_URL}/sales_app/v1/qc_action/`, payload, {
      headers: partnerHeader(),
    });
  };
  //sales_app/v1/qc_action/'
  getHunterLeadList = (
    pageNo: number,
    search: string | null | undefined,
    fromDate: string | null | undefined,
    toDate: string | null | undefined,
    centerList: string | null | undefined,
    productType: number[] | null | undefined,
    selectedOptions: string | null | undefined,
    selectedOptionsUploadType: string | null | undefined
  ): Promise<AxiosResponse<any>> => {
    let url1 = `${this.ARTH_APP_URL}/sales_app/v1/get_hunting_lead_list/`;
    let url = {};
    // Check if search is not null or undefined
    if (search) {
      url["search"] = search;
    }

    // Check if active is true
    if (centerList) {
      url["center"] = centerList;
    }
    if (productType) {
      url["products"] = productType;
    }
    if (selectedOptions) {
      url["status"] = selectedOptions;
    }
    if (selectedOptionsUploadType) {
      url["lead_type"] = selectedOptionsUploadType;
    }
    if (toDate) {
      url["to_date"] = toDate;
    }
    if (fromDate) {
      url["from_date"] = fromDate;
    }
    if (pageNo) {
      url["page"] = pageNo;
      url["per_page"] = 20;
    }

    return axios.get(`${url1}`, { params: url, headers: partnerHeader() });
  };

  getHunterLeadListFos = (pageNo): Promise<AxiosResponse<any>> => {
    let url = `${this.ARTH_APP_URL}/sales_app/v1/get_hunting_lead_list/?`;
    url += `page=${pageNo}`;
    return axios.get(`${url}`, { headers: partnerHeader() });
  };
  getHunterLeadListFosQC = (form_id): Promise<AxiosResponse<any>> => {
    let url = `${this.ARTH_APP_URL}/sales_app/v1/get_hunting_lead_list/?`;
    url += `form_id=${form_id}`;
    return axios.get(`${url}`, { headers: partnerHeader() });
  };
  getcenterbyemployeename = (branch_id): Promise<AxiosResponse<any>> => {
    let url = `${this.ARTH_APP_URL}/sales_app/v1/fetch_branch_tasks/?branch=${branch_id}`;
    return axios.get(`${url}`, { headers: partnerHeader() });
  };
  getSimpleDownloadLead = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(
      `${this.ARTH_APP_URL}/sales_app/v1/download_sample/`,
      payload,
      { headers: partnerHeader() }
    );
  };
  uploadExcelLead = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(
      `${this.ARTH_APP_URL}/sales_app/v1/upload_excel/`,
      payload,
      { headers: partnerHeaderFromdata() }
    );
  };
  getdigitalhuntinglogs = (pageNo: any): Promise<AxiosResponse<any>> => {
    let url = `${this.ARTH_APP_URL}/sales_app/v1/digital_hunting_logs/?`;
    url += `page=${pageNo}`;
    return axios.get(`${url}`, { headers: partnerHeader() });
  };
  getemployeeList = (
    search,
    filterdata,
    centerName,
    pageNo
  ): Promise<AxiosResponse<any>> => {
    let url = `${this.ARTH_APP_URL}/sales_app/v1/employee_list/?`;
    // Check if search is not null or undefined
    if (search) {
      url += `search=${search}&`;
    }

    // Check if active is true
    if (filterdata) {
      url += `role=${filterdata}&`;
    }
    if (centerName) {
      url += `branch=${centerName}&`;
    }

    // Add the page number parameter
    url += `page=${pageNo}&per_page=${20}`;
    return axios.get(`${url}`, { headers: partnerHeader() });
  };
  getmastertaskList = (pageNo: any): Promise<AxiosResponse<any>> => {
    let url = `${this.ARTH_APP_URL}/sales_app/v1/master_task_list/`;
    // url += `page=${pageNo}`;
    return axios.get(`${url}`, { headers: partnerHeader() });
  };

  getHotLeadsTask = (isComplete, page): Promise<AxiosResponse<any>> => {
    let url = `${this.ARTH_APP_URL}/sales_app/v1/bdo_hlp_tasks/`;

    let data = {};

    if (isComplete == false) {
      data["is_complete"] = isComplete;
    }
    if (isComplete === true || isComplete === "" || isComplete === null) {
      data["is_complete"] = null;
    }
    if (page) {
      data["page"] = page;
    }

    return axios.get(`${url}`, { params: data, headers: partnerHeader() });
  };

  getReport = async () => {
    try {
      let url = `${this.ARTH_APP_URL}/sales_app/v1/assignment_report/`;
      const requestOptions = {
        headers: partnerHeader(),
      };
      const response = await fetch(url, requestOptions);
      return await response.json();
    } catch (error) {
      console.log("Error - ", error);
    }
  };

  updateMasterTasks = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.patch(
      `${this.ARTH_APP_URL}/sales_app/v1/update_master_tasks/`,
      payload,
      { headers: partnerHeader() }
    );
  };

  updateActiveEmployee = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.patch(
      `${this.ARTH_APP_URL}/sales_app/v1/employee_avialable/`,
      payload,
      { headers: partnerHeader() }
    );
  };

  updateActiveEmployeeval = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(
      `${this.ARTH_APP_URL}/sales_app/v1/leave_dates/`,
      payload,
      { headers: partnerHeader() }
    );
  };
  getRoleList = (): Promise<AxiosResponse<any>> => {
    let url = `${this.ARTH_APP_URL}/sales_app/v1/role_list/?role=all`;
    return axios.get(`${url}`, { headers: partnerHeader() });
  };

  getFetchTaskscentralops = (
    page,
    search,
    fromDate,
    toDate,
    centerList,
    productTypeValue,
    selectedOptions,
    role,
    perpage,
    searchtype,
    taskName
  ): Promise<AxiosResponse<any>> => {
    let url1 = `${this.ARTH_APP_URL}/sales_app/v1/fetch_tasks/?`;
    //let url1 = `${this.ARTH_APP_URL}/sales_app/v1/get_hunting_lead_list/`;
    let url = {};

    // Check if search is not null or undefined
    if (searchtype === "Search" && search) {
      url["search"] = search;
    }
    if (searchtype === "Task Id" && search) {
      url["task_id_filter"] = search;
    }
    if (searchtype === "Employee Id" && search) {
      url["emp_id"] = search;
    }
    if (searchtype === "Employee Name" && search) {
      url["emp_name"] = search;
    }
    // Check if active is true
    if (centerList) {
      url["center"] = centerList;
    }
    if (role) {
      url["role"] = role;
    }
    if (taskName) {
      url["task_type"] = taskName;
    }
    if (selectedOptions) {
      url["status"] = selectedOptions;
    }
    //  if (selectedOptionsUploadType) {
    //    url['lead_type']=selectedOptionsUploadType;
    //  }
    if (toDate) {
      url["end_date"] = toDate;
    }
    if (fromDate) {
      url["start_date"] = fromDate;
    }
    if (page) {
      url["page"] = page;
      url["per_page"] = perpage;
    }

    return axios.get(`${url1}`, { params: url, headers: partnerHeader() });
  };

  getFetchTasksAlldata = (
    page,
    search,
    fromDate,
    toDate,
    centerList,
    productTypeValue,
    selectedOptions,
    role,
    perpage,
    searchtype,
    taskName
  ): Promise<AxiosResponse<any>> => {
    let url1 = `${this.ARTH_APP_URL}/sales_app/v1/fetch_tasks/?`;
    //let url1 = `${this.ARTH_APP_URL}/sales_app/v1/get_hunting_lead_list/`;
    let url = {};

    // Check if search is not null or undefined
    if (searchtype === "Search" && search) {
      url["search"] = search;
    }
    if (searchtype === "Task Id" && search) {
      url["task_id_filter"] = search;
    }
    if (searchtype === "Employee Id" && search) {
      url["emp_id"] = search;
    }
    if (searchtype === "Employee Name" && search) {
      url["emp_name"] = search;
    }
    // Check if active is true
    if (centerList) {
      url["center"] = centerList;
    }
    if (role) {
      url["role"] = role;
    }
    if (taskName) {
      url["task_type"] = taskName;
    }
    if (selectedOptions) {
      url["status"] = selectedOptions;
    }
    //  if (selectedOptionsUploadType) {
    //    url['lead_type']=selectedOptionsUploadType;
    //  }
    if (toDate) {
      url["end_date"] = toDate;
    }
    if (fromDate) {
      url["start_date"] = fromDate;
    }
    if (page) {
      url["page"] = page;
      url["per_page"] = perpage;
    }

    return axios.get(`${url1}`, { params: url, headers: partnerHeader() });
  };

  getFetchTasksSelf = (
    page,
    search,
    fromDate,
    toDate,
    centerList,
    productTypeValue,
    selectedOptions
  ): Promise<AxiosResponse<any>> => {
    let url = `${this.ARTH_APP_URL}/sales_app/v1/fetch_tasks?my_tasks=true`;
    return axios.get(`${url}`, { headers: partnerHeader() });
  };
  // getFetchTaskCurrentSelf = (): Promise<AxiosResponse<any>> => {
  //   let url = `${this.ARTH_APP_URL}/sales_app/v1/current_task`;
  //   return axios.get(`${url}`, { headers: partnerHeader() });
  // }
  getFetchTaskCurrentSelf = (): Promise<AxiosResponse<any>> => {
    return axios.get(`${this.ARTH_APP_URL}/sales_app/v1/current_task/`, {
      headers: partnerHeader(),
    });
  };

  uploadExcelTaskAssignment = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.put(
      `${this.ARTH_APP_URL}/hotleadapi/taskmanagement/`,
      payload,
      { headers: partnerHeaderFromdata() }
    );
  };
  uploadncUpload = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.put(`${this.ARTH_APP_URL}/hotleadapi/nc_upload/`, payload, {
      headers: partnerHeaderFromdata(),
    });
  };
  getExcelTaskAssignment = (pageNo: any): Promise<AxiosResponse<any>> => {
    let url = `${this.ARTH_APP_URL}/sales_app/v1/digital_hunting_logs/?`;
    url += `page=${pageNo}`;
    return axios.get(`${url}`, { headers: partnerHeader() });
  };
  //v1/shop_information/
  getshopInformation = (taskid: any): Promise<AxiosResponse<any>> => {
    let url = `${this.ARTH_APP_URL}/sales_app/v1/shop_information/?`;
    url += `task_id=${taskid}`;
    return axios.get(`${url}`, { headers: partnerHeader() });
  };
  //v1/add_task_log/
  getaddtaskLog = (pageNo: any): Promise<AxiosResponse<any>> => {
    let url = `${this.ARTH_APP_URL}/sales_app/v1/add_task_log/?`;
    url += `page=${pageNo}`;
    return axios.get(`${url}`, { headers: partnerHeader() });
  };
  getTaskbyiddata = (taskid: any): Promise<AxiosResponse<any>> => {
    let url = `${this.ARTH_APP_URL}/sales_app/v1/fetch_tasks/?`;
    url += `task_id=${taskid}`;
    return axios.get(`${url}`, { headers: partnerHeader() });
  };
  getTaskbyiddataval = (taskid: any): Promise<AxiosResponse<any>> => {
    let url = `${this.ARTH_APP_URL}/sales_app/v1/get_task_data/${taskid}/`;
    return axios.get(`${url}`, { headers: partnerHeader() });
  };
  updateFetchTaskCurrentSelf = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(
      `${this.ARTH_APP_URL}/sales_app/v1/current_task/`,
      payload,
      { headers: partnerHeader() }
    );
  };
  updatecafpermission = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(
      `${this.ARTH_APP_URL}/common/v1/set_primary_role/`,
      payload,
      { headers: partnerHeader() }
    );
  };

  getSimpleDownloadTask = (): Promise<AxiosResponse<any>> => {
    let url = `${this.ARTH_APP_URL}/partners/download-sample/task_format/`;
    return axios.get(`${url}`, { headers: partnerHeader() });
  };
  getSimpleDownloadTaskMagicQR = (): Promise<AxiosResponse<any>> => {
    let url = `${this.ARTH_APP_URL}/partners/download-sample/magic_qr_format/`;
    return axios.get(`${url}`, { headers: partnerHeader() });
  };
  changeTaskAssignee = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(
      `${this.ARTH_APP_URL}/sales_app/v1/change_task_assignee/`,
      payload,
      { headers: partnerHeaderFromdata() }
    );
  };
  RevealNumber = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(
      `${this.ARTH_APP_URL}/sales_app/v1/reveal_number/`,
      payload,
      { headers: partnerHeader() }
    );
  };
  Createdisposition = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(
      `${this.ARTH_APP_URL}/sales_app/v1/disposition/`,
      payload,
      { headers: partnerHeader() }
    );
  };
  getEmployeebyid = (id): Promise<AxiosResponse<any>> => {
    return axios.get(
      `${this.ARTH_APP_URL}/sales_app/v1/calendar_data/?employee_id=${id}`,
      { headers: partnerHeader() }
    );
  };
  getnccDownload = (): Promise<AxiosResponse<any>> => {
    return axios.get(
      `${this.ARTH_APP_URL}/partners/download-sample/nc_format/`,
      { headers: partnerHeader() }
    );
  };
  UploaddispositionFile = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(
      `${this.ARTH_APP_URL}/sales_app/v1/upload_dispostion_files/`,
      payload,
      { headers: partnerHeaderFromdata() }
    );
  };
  AttendanceClockin = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(`${this.ARTH_APP_URL}/sales_app/v1/clockin/`, payload, {
      headers: partnerHeader(),
    });
  };
  getAttendanceClockin = (): Promise<AxiosResponse<any>> => {
    return axios.get(`${this.ARTH_APP_URL}/sales_app/v1/clockin/`, {
      headers: partnerHeader(),
    });
  };
  getAttendanceClockinbyid = (id): Promise<AxiosResponse<any>> => {
    return axios.get(
      `${this.ARTH_APP_URL}/sales_app/v1/clockin/?employee_id=${id}`,
      { headers: partnerHeader() }
    );
  };
  AttendanceClockout = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.patch(`${this.ARTH_APP_URL}/sales_app/v1/clockout/`, payload, {
      headers: partnerHeader(),
    });
  };
  // getdashboardclc = (): Promise<AxiosResponse<any>> => {
  //   let url = `${this.ARTH_APP_URL}/sales_app/v1/sales_dashboard/`;
  //   return axios.get(`${url}`, { headers: partnerHeader() });
  // }
  getdashboardclc = (fromdate, todate): Promise<AxiosResponse<any>> => {
    // console.log("searchfilterPageno:",search, filter, pageNo);
    let url = `${this.ARTH_APP_URL}/sales_app/v1/sales_dashboard/`;

    // Check if search is not null or undefined
    if (fromdate) {
      url += `?from_date=${fromdate}&`;
    }

    // Check if active is true
    if (todate) {
      url += `to_date=${todate}`;
    }

    return axios.get(`${url}`, { headers: partnerHeader() });
  };
  getdashboardemployeebased = (
    id,
    fromdate,
    todate
  ): Promise<AxiosResponse<any>> => {
    let url = `${this.ARTH_APP_URL}/sales_app/v1/sales_dashboard/`;
    if (id) {
      url += `?employee_id=${id}&`;
    }
    // Check if search is not null or undefined
    if (fromdate) {
      url += `from_date=${fromdate}&`;
    }

    // Check if active is true
    if (todate) {
      url += `to_date=${todate}`;
    }

    //let url = `${this.ARTH_APP_URL}/sales_app/v1/sales_dashboard/?employee_id=${id}`;
    return axios.get(`${url}`, { headers: partnerHeader() });
  };
  getEmployeebyList = (): Promise<AxiosResponse<any>> => {
    return axios.get(`${this.ARTH_APP_URL}/sales_app/v1/fetch_employees/`, {
      headers: partnerHeader(),
    });
  };
  getEmployeerolebyList = (branch): Promise<AxiosResponse<any>> => {
    let url = `${this.ARTH_APP_URL}/sales_app/v1/fetch_employees/?`;
    if (branch) {
      url += `branch_id=${branch}`;
    }
    return axios.get(`${url}`, { headers: partnerHeader() });
  };

  getEmployeeLists = (branch): Promise<AxiosResponse<any>> => {
    let url = `${this.ARTH_APP_URL}/sales_app/v1/branch_employee_list/?`;
    if (branch) {
      url += `branch_id=${branch}`;
    }
    return axios.get(`${url}`, { headers: partnerHeader() });
  };

  getDispostionDashboardData = (branch, employee) => {
    let url = `${this.ARTH_APP_URL}/sales_app/v1/disposition_dashboard/`;
    let data = {};

    if (branch) {
      data["branch_id"] = branch;
    }

    if (employee) {
      data["employee"] = employee;
    }
    return axios.get(`${url}`, { params: data, headers: partnerHeader() });
  };
  getEmployeerolebyListtracking = (
    branch,
    role,
    searchs
  ): Promise<AxiosResponse<any>> => {
    let url = `${this.ARTH_APP_URL}/sales_app/v1/fetch_employees/?`;
    if (branch) {
      url += `branch_id=${branch}`;
    }
    // Check if search is not null or undefined
    if (role) {
      url += `&role=${role}&`;
    }
    if (searchs) {
      url += `search=${searchs}`;
    }

    //let url = `${this.ARTH_APP_URL}/sales_app/v1/sales_dashboard/?employee_id=${id}`;
    return axios.get(`${url}`, { headers: partnerHeader() });
  };
  getLiveTrackingData = (employee_id, date): Promise<AxiosResponse<any>> => {
    let url = `${this.ARTH_APP_URL}/sales_app/v1/live_track_panel/?`;

    if (employee_id) {
      url += `employee_id=${employee_id}&`;
    }

    if (date) {
      url += `date=${date}`;
    }
    return axios.get(`${url}`, { headers: partnerHeader() });
  };

  getEmployeebyidtracking = (
    id,
    keyvalue,
    date
  ): Promise<AxiosResponse<any>> => {
    let url = `${this.ARTH_APP_URL}/sales_app/v1/live_location/?`;
    if (id) {
      url += `employee_id=${id}&`;
    }
    // Check if search is not null or undefined
    if (keyvalue) {
      url += `key=${keyvalue}&`;
    }
    if (date) {
      url += `date=${date}`;
    }

    //let url = `${this.ARTH_APP_URL}/sales_app/v1/sales_dashboard/?employee_id=${id}`;
    return axios.get(`${url}`, { headers: partnerHeader() });
  };
  Employeebytrackingdata = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(
      `${this.ARTH_APP_URL}/sales_app/v1/live_location/`,
      payload,
      { headers: partnerHeader() }
    );
  };
  getEmployeebyidtask = (): Promise<AxiosResponse<any>> => {
    let url = `${this.ARTH_APP_URL}/sales_app/v1/today_locations/`;
    return axios.get(`${url}`, { headers: partnerHeader() });
  };
  uploadExcelTaskAssignmentMagicQR = (
    payload: any
  ): Promise<AxiosResponse<any>> => {
    return axios.put(
      `${this.ARTH_APP_URL}/sales_app/v1/magic_upload/`,
      payload,
      { headers: partnerHeaderFromdata() }
    );
  };
  getheatMapLocation = (branch, roledata): Promise<AxiosResponse<any>> => {
    let url = `${this.ARTH_APP_URL}/sales_app/v1/heat_map/?`;
    if (branch) {
      url += `branch_id=${branch}&`;
    }
    // Check if search is not null or undefined
    if (roledata) {
      url += `role=${roledata}`;
    }

    //let url = `${this.ARTH_APP_URL}/sales_app/v1/sales_dashboard/?employee_id=${id}`;
    return axios.get(`${url}`, { headers: partnerHeader() });
    //   let url = `${this.ARTH_APP_URL}/sales_app/v1/heat_map/`;
    //  return axios.get(`${url}`, { headers: partnerHeader() });
  };
  getEmployeeChannelList = (): Promise<AxiosResponse<any>> => {
    let url = `${this.ARTH_APP_URL}/partners/partners-listing/Channel/`;
    return axios.get(`${url}`, { headers: partnerHeader() });
  };
  validateUdyam = (
    udyam_number: string,
    customer_code: string,
    partner_code: string
  ): Promise<AxiosResponse<any>> => {
    return axios.post(
      `${this.ARTH_APP_URL}/check_valid_udyam`,
      { udyam_number, customer_code, partner_code },
      { headers: partnerHeader() }
    );
  };
  sendImage = (sdk_user: any): Promise<AxiosResponse<any>> => {
    return axios.patch(
      `${this.ARTH_APP_URL}/profiles/me`,
      { sdk_user },
      { headers: partnerHeader() }
    );
  };
  UploadAadhar = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(
      `${this.ARTH_APP_URL}/sales_app/v1/upload_aadhar/`,
      payload,
      { headers: partnerHeader() }
    );
  };
  CreateFormBsuinessPrime = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(
      `${this.ARTH_APP_URL}/sales_app/v1/create_paymint_task/`,
      payload,
      { headers: partnerHeaderFromdata() }
    );
  };
  CreateFormValidateUdyamAadhaarNumber = (
    payload: any
  ): Promise<AxiosResponse<any>> => {
    return axios.post(
      `${this.ARTH_APP_URL}/sales_app/v1/validate_udyam_aadhaar/`,
      payload,
      { headers: partnerHeaderFromdata() }
    );
  };
  UpdateFormValidateUdyamAadhaarNumber = (
    payload: any,
    id
  ): Promise<AxiosResponse<any>> => {
    return axios.put(
      `${this.ARTH_APP_URL}/sales_app/v1/update_paymint_task/${id}/`,
      payload,
      { headers: partnerHeaderFromdata() }
    );
  };

  sendEnachLink = (payload): Promise<AxiosResponse<any>> => {
    return axios.post(`${this.ARTH_URL}/v2/enach/sendenachlink/`, payload, {
      headers: headers(),
    });
  };

  getBusinessPrimeTask = (is_complete, page): Promise<AxiosResponse<any>> => {
    let url = `${this.ARTH_APP_URL}/sales_app/v1/bp_tasks?`;

    console.log("Complete --", is_complete);
    console.log("Pagesss ----", page);

    if (is_complete) {
      url += `complete=${is_complete}&`;
    }
    if (page) {
      url += `page=${page}`;
    }
    return axios.get(`${url}`, {
      headers: partnerHeader(),
    });
  };
}
