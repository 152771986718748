import Swal from "sweetalert2";
import "./toaster.scss";
import "./utilities.scss";

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-success m-2",
    cancelButton: "btn btn-outline-secondary m-2",
  },
  buttonsStyling: false,
});

const imageViewStyle = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-danger",
  },
  buttonsStyling: false,
});

export class Toaster {
  show(error: boolean = true, text: string = "Something went wrong!!") {
    if (error) {
      Swal.fire({
        position: "bottom-end",
        icon: "error",
        // title: "Oops...",
        text: text,
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      Swal.fire({
        position: "bottom-end",
        icon: "success",
        title: "Congratulations",
        text: text,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }
  showhuntingform(
    error: boolean = true,
    text: string = "Something went wrong!!"
  ) {
    if (error) {
      Swal.fire({
        position: "bottom-end",
        icon: "error",
        text: text,
        showConfirmButton: false,
        timer: 5000,
      });
    } else {
      Swal.fire({
        position: "bottom-end",
        icon: "success",
        title: "Congratulations",
        text: text,
        showConfirmButton: false,
        timer: 3000,
      });
    }
  }
  confirmation(
    confirmButtonText: string = "Approve",
    text: string = "This sends an e-mail to the partner to update his/her profile!"
  ) {
    return swalWithBootstrapButtons.fire({
      title:
        confirmButtonText === "ReKYC"
          ? "Are you sure to send this for Re-KYC?"
          : "Are you sure?",
      text: confirmButtonText === "ReKYC" ? "" : text,
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
      reverseButtons: true,
      customClass: {
        container: "custom-modal-container",
        confirmButton: "btn btn-success m-2",
        cancelButton: "btn btn-outline-secondary m-2",
      },
    });
  }
  confirmationunnati(
    confirmButtonText: string = "Approve",
    text: string = "This sends an e-mail to the partner to update his/her profile!"
  ) {
    return swalWithBootstrapButtons.fire({
      title:
        confirmButtonText === "ReKYC"
          ? "Are you sure to send this for Re-KYC?"
          : "Are you sure?",
      text: confirmButtonText === "ReKYC" ? "" : "",
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
      reverseButtons: true,
      customClass: {
        container: "custom-modal-container",
        confirmButton: "btn btn-success m-2",
        cancelButton: "btn btn-outline-secondary m-2",
      },
    });
  }

  viewImage(img: string, height: any = 400) {
    imageViewStyle.fire({
      imageUrl: img,
      imageHeight: height,
      imageWidth: 400,
      imageAlt: "Image",
      background: "transparent",
      showConfirmButton: true,
    });
  }

  // inputConfirmation(
  //   confirmButtonText: string = "Approve",
  //   partnerType: string,
  //   cancelButtonText: string = "Cancel"
  // ) {
  //   if (confirmButtonText === "Approve") {
  //     if (partnerType === "HLP") {
  //       const swalPromise = swalWithBootstrapButtons.fire({
  //         title:
  //           '<div class="hlp-type"><div class="hlp-type-title">Before approving, please tag partner to a category</div></div>',
  //         html: `
  //           <div class="hlp-type">
  //               <div class="select-hlp">
  //                   <select id="partnerCategory" class="swal2-select">
  //                       <option value="" data-color="#717ABC">Select</option>
  //                       <option value="Small" data-color="#DC3545" text-color="#E3FFEF">Small</option>
  //                       <option value="Medium" data-color="#F1B300" text-color="#E3FFEF">Medium</option>
  //                       <option value="Large" text-color="#10B759" data-color="#E3FFEF">Large</option>
  //                   </select>
  //               </div>

  //           </div>
  //            <div class="hlp-type" style="display: block; margin-top: 10px;">
  //                   <input id="remarkInput" type="text"  placeholder="Enter remark (optional)">
  //               </div>
  //           `,
  //         showCancelButton: true,
  //         confirmButtonText: confirmButtonText,
  //         cancelButtonText: cancelButtonText,
  //         preConfirm: () => {
  //           const partnerCategoryInput = document.getElementById(
  //             "partnerCategory"
  //           ) as HTMLSelectElement;
  //           const partnerCategory = partnerCategoryInput.value;
  //           const remarkInput = document.getElementById(
  //             "remarkInput"
  //           ) as HTMLInputElement;
  //           const remark = remarkInput.value; // Optional remark

  //           if (!partnerCategory) {
  //             Swal.showValidationMessage("Please select a partner category.");
  //           }

  //           return {
  //             partnerCategory: partnerCategory,
  //             remark: remark, // Send remark even if empty
  //           };
  //         },
  //       });

  //       const partnerCategorySelect = document.getElementById(
  //         "partnerCategory"
  //       ) as HTMLSelectElement;
  //       partnerCategorySelect.addEventListener("change", function () {
  //         const select = this as HTMLSelectElement;
  //         const selectedOption = select.options[select.selectedIndex];
  //         const color = selectedOption.getAttribute("data-color");
  //         const textColor = selectedOption.getAttribute("text-color");
  //         select.style.backgroundColor = color;
  //         select.style.color = textColor;
  //       });

  //       return swalPromise;
  //     } else {
  //       const swalPromise = swalWithBootstrapButtons.fire({
  //         title:
  //           '<div class="reason-to-reject"><div class="hlp-reject-title">Are you sure you want to Approve?</div></div>',
  //         showCancelButton: true,
  //         confirmButtonText: confirmButtonText,
  //         cancelButtonText: cancelButtonText,
  //         // preConfirm: () => {
  //         //   const partnerCategoryInput = document.getElementById('partnerCategory') as HTMLSelectElement;
  //         //   const partnerCategory = partnerCategoryInput.value;

  //         //   return { partnerCategory: partnerCategory };
  //         // }
  //       });
  //       return swalPromise;
  //     }
  //   } else {
  //     const swalPromise = swalWithBootstrapButtons.fire({
  //       title:
  //         '<div class="reason-to-reject"><div class="hlp-reject-title">Are you sure you want to reject?</div></div>',
  //       html: `
  //           <div class="reason-to-reject">
  //               <div class="reject-reason">
  //                   <select class="reason-to-reject" id="reason-to-reject-dropdown">
  //                       <option value="">Select a reason</option>
  //                       <option value="Age Criteria">Age Criteria</option>
  //                       <option value="Bureau Score">Bureau Score</option>
  //                       <option value="Bad Repayment">Bad Repayment</option>
  //                       <option value="Negative PD">Negative PD</option>
  //                       <option value="Out of Occupation">Out of Occupation</option>
  //                       <option value="Negative Occupation">Negative Occupation</option>
  //                       <option value="Inactive/Suspended GST">Inactive/Suspended GST</option>
  //                       <option value="Low Active Merchants/Retailers">Low Active Merchants/Retailers</option>
  //                       <option value="KYC Breach">KYC Breach</option>
  //                       <option value="Other">Other</option>
  //                   </select>
  //                   <div id="other-reason-container" style="display: none; margin-top: 10px;">
  //                       <input type="text" id="other-reason-text" class="reason-to-reject" placeholder="Please specify reason" />
  //                   </div>
  //               </div>
  //           </div>
  //       `,
  //       showCancelButton: true,
  //       confirmButtonText: confirmButtonText,
  //       cancelButtonText: cancelButtonText,
  //       preConfirm: () => {
  //         const partnerRejectReasonDropdown = document.getElementById(
  //           "reason-to-reject-dropdown"
  //         ) as HTMLSelectElement;
  //         const partnerRejectReason = partnerRejectReasonDropdown.value;

  //         let rejectPartnerReason = partnerRejectReason;
  //         if (partnerRejectReason === "Other") {
  //           const otherReasonInput = document.getElementById(
  //             "other-reason-text"
  //           ) as HTMLInputElement;
  //           const otherReasonText = otherReasonInput.value;
  //           if (!otherReasonText) {
  //             Swal.showValidationMessage(
  //               'Please provide a reason if you select "Other".'
  //             );
  //             return false; // Prevent submission if "Other" is selected but no reason is provided.
  //           }
  //           rejectPartnerReason = otherReasonText;
  //         }

  //         if (!rejectPartnerReason) {
  //           Swal.showValidationMessage(
  //             "Please select or provide a reason to reject this partner."
  //           );
  //           return false;
  //         }

  //         return { rejectPartnerReason };
  //       },
  //       didOpen: () => {
  //         // Show the "Other" text input field when "Other" is selected
  //         const reasonSelect = document.getElementById(
  //           "reason-to-reject-dropdown"
  //         ) as HTMLSelectElement;
  //         reasonSelect.addEventListener("change", () => {
  //           const otherReasonContainer = document.getElementById(
  //             "other-reason-container"
  //           ) as HTMLElement;
  //           if (reasonSelect.value === "Other") {
  //             otherReasonContainer.style.display = "block";
  //           } else {
  //             otherReasonContainer.style.display = "none";
  //           }
  //         });
  //       },
  //     });

  //     return swalPromise;
  //   }
  // }

  inputConfirmation(
    confirmButtonText: string = "Approve",
    partnerType: string,
    cancelButtonText: string = "Cancel"
  ) {
    // Common HTML for the new dropdown
    const partnerLeadDropdownHTML = `
      <div class="partner-lead-dropdown" style="margin-top: 10px;">
          <select id="partnerLeadDropdown" class="swal2-select">
              <option value="">Select Lead Type</option>
              <option value="P1" >P1 - With Hot Lead</option>
              <option value="P2" >P2 - Without Hot Lead</option>
          </select>
      </div>
      <div class="remark-input-container" style="margin-top: 10px;">
          <input id="remarkInput" type="text" class="swal2-input" placeholder="Enter remark (optional)">
      </div>
    `;

    if (confirmButtonText === "Approve") {
      if (partnerType === "HLP") {
        return swalWithBootstrapButtons.fire({
          title:
            '<div class="hlp-type"><div class="hlp-type-title">Before approving, please tag partner to a category</div></div>',
          html: `
            <div class="hlp-type">
                <div class="select-hlp">
                    <select id="partnerCategory" class="swal2-select">
                        <option value="" data-color="#717ABC">Select</option>
                        <option value="Small" data-color="#DC3545" text-color="#E3FFEF">Small</option>
                        <option value="Medium" data-color="#F1B300" text-color="#E3FFEF">Medium</option>
                        <option value="Large" text-color="#F1B300" data-color="#E3FFEF">Large</option>
                    </select>
                </div>
            </div>
            ${partnerLeadDropdownHTML}
          `,
          showCancelButton: true,
          confirmButtonText,
          cancelButtonText,
          preConfirm: () => {
            const partnerCategoryInput = document.getElementById(
              "partnerCategory"
            ) as HTMLSelectElement;
            const partnerCategory = partnerCategoryInput.value;
            const remarkInput = document.getElementById(
              "remarkInput"
            ) as HTMLInputElement;
            const remark = remarkInput.value;

            const partnerLeadDropdown = document.getElementById(
              "partnerLeadDropdown"
            ) as HTMLSelectElement;
            const partnerLead = partnerLeadDropdown.value;

            if (!partnerCategory) {
              Swal.showValidationMessage("Please select a partner category.");
              return false;
            }

            if (!partnerLead) {
              Swal.showValidationMessage("Please select a lead type.");
              return false;
            }

            return {
              partnerCategory,
              remark,
              partnerLead,
            };
          },
          didOpen: () => {
            const partnerLeadSelect = document.getElementById(
              "partnerLeadDropdown"
            ) as HTMLSelectElement;
            partnerLeadSelect.addEventListener("change", function () {
              const select = this as HTMLSelectElement;
              const selectedOption = select.options[select.selectedIndex];
              const color = selectedOption.getAttribute("data-color");
              select.style.backgroundColor = color;
            });
          },
        });
      } else {
        return swalWithBootstrapButtons.fire({
          title:
            '<div class="reason-to-approve"><div class="hlp-approve-title">Are you sure you want to Approve?</div></div>',
          showCancelButton: true,
          confirmButtonText,
          cancelButtonText,
          preConfirm: () => {
            const partnerLeadDropdown = document.getElementById(
              "partnerLeadDropdown"
            ) as HTMLSelectElement;
            const partnerLead = partnerLeadDropdown.value;

            if (!partnerLead) {
              Swal.showValidationMessage("Please select a lead type.");
              return false;
            }

            return { partnerLead };
          },
        });
      }
    } else if (confirmButtonText === "Reject") {
      return swalWithBootstrapButtons.fire({
        title:
          '<div class="reason-to-reject"><div class="hlp-reject-title">Are you sure you want to reject?</div></div>',
        html: `
        <div class="reason-to-reject">
            <div class="reject-reason">
                <select class="swal2-input" id="reason-to-reject-dropdown">
                    <option value="">Select a reason</option>
                    <option value="Age Criteria">Age Criteria</option>
                    <option value="Bureau Score">Bureau Score</option>
                    <option value="Bad Repayment">Bad Repayment</option>
                    <option value="Negative PD">Negative PD</option>
                    <option value="Out of Occupation">Out of Occupation</option>
                    <option value="Negative Occupation">Negative Occupation</option>
                    <option value="Inactive/Suspended GST">Inactive/Suspended GST</option>
                    <option value="Low Active Merchants/Retailers">Low Active Merchants/Retailers</option>
                    <option value="KYC Breach">KYC Breach</option>
                    <option value="Other">Other</option>
                </select>
                <div id="other-reason-container" style="display: none; margin-top: 10px;">
                    <input type="text" id="other-reason-text" class="swal2-input" placeholder="Please specify reason" />
                </div>
            </div>
            <div class="reject-reason" style="margin-top: 10px;">
                <input id="rejectRemarkInput" type="text" class="swal2-input"  placeholder="Enter remark (optional)">
            </div>
        </div>
      `,
        showCancelButton: true,
        confirmButtonText,
        cancelButtonText,
        preConfirm: () => {
          const reasonDropdown = document.getElementById(
            "reason-to-reject-dropdown"
          ) as HTMLSelectElement;
          const reason = reasonDropdown.value;
          const remarkInput = document.getElementById(
            "rejectRemarkInput"
          ) as HTMLInputElement;
          const remark = remarkInput.value;

          if (!reason) {
            Swal.showValidationMessage("Please select or provide a reason.");
            return false;
          }

          if (reason === "Other") {
            const otherReasonInput = document.getElementById(
              "other-reason-text"
            ) as HTMLInputElement;
            const otherReason = otherReasonInput.value;

            if (!otherReason) {
              Swal.showValidationMessage(
                'Please provide a reason if you select "Other".'
              );
              return false;
            }
            return { reason: otherReason, remark };
          }

          return { reason, remark };
        },
        didOpen: () => {
          const reasonDropdown = document.getElementById(
            "reason-to-reject-dropdown"
          ) as HTMLSelectElement;
          reasonDropdown.addEventListener("change", () => {
            const otherReasonContainer = document.getElementById(
              "other-reason-container"
            ) as HTMLElement;
            if (reasonDropdown.value === "Other") {
              otherReasonContainer.style.display = "block";
            } else {
              otherReasonContainer.style.display = "none";
            }
          });
        },
      });
    } else if (confirmButtonText === "Hold") {
      return swalWithBootstrapButtons.fire({
        title:
          '<div class="reason-to-hold"><div class="hlp-hold-title">Are you sure you want to place on Hold?</div></div>',
        html: `
        <div class="reason-to-hold">
            <div class="hold-reason">
                <select class="swal2-input" id="reason-to-hold-dropdown">
                    <option value="">Select a reason</option>
                    <option value="HLP Not Contactable">HLP Not Contactable</option>
                    <option value="References Not Contactable">References Not Contactable</option>
                    <option value="Other">Other</option>
                </select>
                <div id="other-hold-reason-container" style="display: none; margin-top: 10px;">
                    <input type="text" id="other-hold-reason-text" class="swal2-input" placeholder="Please specify reason" />
                </div>
            </div>
            <div class="hold-reason" style="margin-top: 10px;">
                <input id="holdRemarkInput" type="text" class="swal2-input" placeholder="Enter remark (optional)">
            </div>
        </div>
      `,
        showCancelButton: true,
        confirmButtonText,
        cancelButtonText,
        preConfirm: () => {
          const holdReasonDropdown = document.getElementById(
            "reason-to-hold-dropdown"
          ) as HTMLSelectElement;
          const holdReason = holdReasonDropdown.value;
          const remarkInput = document.getElementById(
            "holdRemarkInput"
          ) as HTMLInputElement;
          const remark = remarkInput.value;

          if (!holdReason) {
            Swal.showValidationMessage("Please select or provide a reason.");
            return false;
          }

          if (holdReason === "Other") {
            const otherHoldReasonInput = document.getElementById(
              "other-hold-reason-text"
            ) as HTMLInputElement;
            const otherHoldReason = otherHoldReasonInput.value;

            if (!otherHoldReason) {
              Swal.showValidationMessage(
                'Please provide a reason if you select "Other".'
              );
              return false;
            }
            return { reason: otherHoldReason, remark };
          }

          return { reason: holdReason, remark };
        },
        didOpen: () => {
          const holdReasonDropdown = document.getElementById(
            "reason-to-hold-dropdown"
          ) as HTMLSelectElement;
          holdReasonDropdown.addEventListener("change", () => {
            const otherHoldReasonContainer = document.getElementById(
              "other-hold-reason-container"
            ) as HTMLElement;
            if (holdReasonDropdown.value === "Other") {
              otherHoldReasonContainer.style.display = "block";
            } else {
              otherHoldReasonContainer.style.display = "none";
            }
          });
        },
      });
    }
  }

  showDocs(text: string = "You won't be able to revert this!") {
    return swalWithBootstrapButtons.fire({
      title: "Documents Required",
      text: text,
      icon: "warning",
      // color: '#10B759',
      showCancelButton: false,
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
      reverseButtons: true,
      customClass: {
        container: "custom-modal-container",
      },
    });
  }
}
