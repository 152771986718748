import React from 'react';
import './button.scss';

const PrimaryButton = (props) => {

  const {buttonContent} = props;

  return (
    <div className='partner-action-button-active'>
      {buttonContent}
    </div>
  )
}

export default PrimaryButton;
