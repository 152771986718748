import { useState } from 'react';
import PrimaryButton from '../button/PrimaryButton';
import { AddWhiteSVGSALES, ClearSvg } from '../../../assets/svg';
import { useNavigate } from "react-router-dom";

import './Filters.scss';
import Dropdown from '../../partner-application/dropdown/Dropdown';

const searchKeywords = {
  "Name": "Name",
  "Mobile No.": "Mobile No.",
  "ID": "ID"
}

const Filters = (props) => {

  const {handleClick,filterValues=[],searchList=[], handleSearchClickclear, viewRole, hasPermission} =props
  const navigate = useNavigate();

  const [currentFilter, setCurrentFilter] = useState('');
  const [searchedText, setSearchedText] = useState('');
  const [searchType, setSearchType] = useState('Mobile No.');
 


  const handleFilterClick = (filter = currentFilter) => {
    setCurrentFilter(filter);
    handleClick(filter, searchedText);
  }

  

  const handleSearchInput = (e) => {
    if (e.target.value ==='') {
      handleSearchClickclear(e.target.value)
      setSearchedText('')
      
    } else {
      setSearchedText(e.target.value);
    }
  }

  const handleSearchClick = () => {
    handleClick(currentFilter, searchedText);
  }
  const createNewPartner = () => {
    navigate("/retailer/create");
  }

  return (
    <nav className="navbar navbar-light">
      <div className="container-fluid p-0">
      {viewRole !== "RetailersInvoice" && (<>
        {
          
          viewRole === "Retailer" ? (<>
         
            <form className="d-flex dataInputlabel margin-bottom-list" >
          <input className="form-control search-input-type search-input form-control-width"  type="search" placeholder={'search'} onChange={(e) => handleSearchInput(e)} />
          <div onClick={handleSearchClick} style={{ width: 50, height: 36 }}><PrimaryButton buttonContent={<AddWhiteSVGSALES />} /></div>
        </form>
          </>):(<>
           
            <form className="d-flex dataInputlabel">
          <input className="form-control search-input-type search-input form-control-width"  type="search" placeholder={'search'} onChange={(e) => handleSearchInput(e)} />
          <div onClick={handleSearchClick} style={{ width: 50, height: 36 }}><PrimaryButton buttonContent={<AddWhiteSVGSALES />} /></div>
        </form>
          </>)
        }
          </>)}
       
        <div className='filters-data d-flex'>
          {filterValues && filterValues?.map((item)=>(<div className={`Approved ${currentFilter === item && 'active-filter'}`} onClick={() => handleFilterClick(item)}>
            {item}
          </div>
        ))}
       
        </div>
        {viewRole === "Retailer" && (<>
          {hasPermission && (
          <>
            <div
              onClick={createNewPartner}
             
            >
              <button style={{ backgroundColor: '#EE3E80',
  color: 'white',
  padding: '10px 20px',
  fontWeight:"bold",
  fontSize: '12px',
  border: '1px solid #EE3E80',
  borderRadius: '5px'}}>  Add Retailer</button>
             
            </div>
           
          </>
        )}
        </>)}
      </div>
    </nav>
  )
}
export default Filters;
