// import { useEffect, useState } from "react";
// import "./mobile-input.scss";
// import { useSelector } from "react-redux/es/hooks/useSelector";
// import { useNavigate, useParams, useSearchParams } from "react-router-dom";
// import { useDispatch } from "react-redux";

// import OtpHandler from "./OtpHandler";
// import { Toaster } from "../../../../utils/utilities/Toaster";
// import { ApiService } from "../../../../services/api/apiService";
// import { LoaderService } from "../../../../utils/utilities/loaderService";
// import { Mixpanel } from "../../../../routing/MixPannelWrapper";
// import LendingPageHeader from "../../../../components/customer-application/headers/LendingPageHeader";
// import LanguageCard from "../../../../components/customer-application/language/LanguageCard";
// import ButtonFooter from "../../../../components/customer-application/button-footer/ButtonFooter";
// import AddArthToHomeScreen from "../../shortcut/Shortcut";
// import CTA from "../../../../components/customer-application/installationcta/CTA";
// import Model from "../../../../components/customer-application/model/Model";
// import { assistedPartnerList } from "../../../../utils/helper-function/helper";

// const MobileInput = ({ device }) => {
//   const [showBottomPopUpBox, setShowBottomPopUpBox] = useState<boolean>(false);
//   const [isJourneyStarted, setIsJourneyStarted] = useState<boolean>(false);
//   const [inputAccepted, setInputAccepted] = useState<boolean>(false);
//   const [isInputWrong, setIsInputWrong] = useState<boolean>(false);
//   const [isOtpRequested, setIsOtpRequested] = useState<boolean>(false);
//   const [inputMsg, setInputMsg] = useState<string>("");
//   const [mobile, setMobile] = useState<string>("");
//   const [otp, setOtp] = useState<string>("");
//   const { partnerSlug } = useParams();
//   const [searchParams, setSearchParams] = useSearchParams();
//   const dispatch = useDispatch();
//   const toaster = new Toaster();

//   const displayText = useSelector((state: any) => state.language.languageText);
//   const navigate = useNavigate();
//   const apiService = new ApiService();
//   const loaderService = new LoaderService();
//   const tncLink =
//     "https://sdk-consent-attachments.s3.ap-south-1.amazonaws.com/Arth-Credit_Terms_%26_Conditions.pdf";
//   const [showModelInfo, setShowModelInfo] = useState<boolean>(false);

//   useEffect(() => {
//     redirectionEventCatch();
//     setTimeout(() => {
//       setShowBottomPopUpBox(true);
//     }, 500);
//   }, []);

//   const journeyStarted = async () => {
//     try {
//       const constraints = {
//         video: {
//           facingMode: "environment",
//         },
//         audio: true,
//       };

//       if (!assistedPartnerList.includes(partnerSlug)) {
//         const stream = await navigator.mediaDevices.getUserMedia(constraints);
//       }
//       const locationPermission = await fetchCustomerLocation();
//       setIsJourneyStarted(true);
//     } catch (error) {
//       alert("User did not grant camera/microphone permission");
//     }
//   };

//   const redirectionEventCatch = () => {
//     if (
//       searchParams.get("utm_source") &&
//       searchParams.get("utm_medium") &&
//       searchParams.get("utm_campaign")
//     ) {
//       sessionStorage.setItem("utm_source", searchParams.get("utm_source"));
//       sessionStorage.setItem("utm_medium", searchParams.get("utm_medium"));
//       sessionStorage.setItem("utm_campaign", searchParams.get("utm_campaign"));
//       Mixpanel.identify(
//         searchParams.get("utm_source") +
//           "-" +
//           searchParams.get("utm_medium") +
//           "-" +
//           searchParams.get("utm_campaign")
//       );
//       Mixpanel.track("Existing Customer-Landing Page", "");
//       Mixpanel.people.set({});
//     } else if (searchParams.get("utm_source")) {
//       Mixpanel.identify(searchParams.get("utm_source"));
//       Mixpanel.track("Existing Customer-Landing Page", "");
//       Mixpanel.people.set({});
//     }
//   };

//   const fetchCustomerLocation = async () => {
//     try {
//       const locationPermission = await new Promise<any>((resolve, reject) => {
//         navigator.permissions
//           .query({
//             name: "geolocation",
//           })
//           .then(function (result) {
//             navigator.geolocation.getCurrentPosition(
//               function (position) {
//                 resolve(position);
//               },
//               function (error) {
//                 reject(error);
//               }
//             );
//             if (result.state === "granted") {
//             } else if (result.state === "denied") {
//               alert("Location permission denied");
//             }
//           });
//       });
//       return locationPermission;
//     } catch (error) {}
//   };

//   const viewTnC = () => {
//     window.open(tncLink, "_blank");
//   };

//   const handleModelClick = () => {
//     setShowModelInfo(false);
//     journeyStarted();
//   };

//   const requestPermissions = () => {
//     setShowModelInfo(true);
//   };

//   const getOTP = () => {
//     apiService
//       .getOTP(mobile)
//       .then((response: any) => {
//         if (response?.data?.message === "OTP sent") {
//           setIsOtpRequested(true);
//         }
//       })
//       .catch((error: any) => {
//         toaster.show(true, "Invalid number");
//       });
//   };

//   const verifyMobileOtp = () => {
//     const payload = {
//       otp: otp,
//       mobile: mobile,
//     };

//     apiService.verifyMobileOtp(payload).then((response: any) => {
//       localStorage.setItem("PWA-token", response?.data?.auth?.token);
//       localStorage.setItem("Device", device);
//       if (
//         searchParams.get("utm_source") &&
//         searchParams.get("utm_medium") &&
//         searchParams.get("utm_campaign")
//       ) {
//         const customer_ucic = response?.data?.loan_data?.loan1?.customer_code;
//         Mixpanel.identify(
//           mobile +
//             "-" +
//             customer_ucic +
//             "-" +
//             searchParams.get("utm_source") +
//             "-" +
//             searchParams.get("utm_medium") +
//             "-" +
//             searchParams.get("utm_campaign") +
//             "-" +
//             localStorage.getItem("Device")
//         );
//         Mixpanel.track("Login", "");
//         Mixpanel.people.set({
//           $user_id: response?.data?.name,
//         });
//       } else if (searchParams.get("utm_source")) {
//         const customer_ucic = response?.data?.loan_data?.loan1?.customer_code;
//         Mixpanel.identify(
//           mobile +
//             "-" +
//             customer_ucic +
//             "-" +
//             searchParams.get("utm_source") +
//             "-" +
//             localStorage.getItem("Device")
//         );
//         Mixpanel.track("Login", "");
//         Mixpanel.people.set({
//           $user_id: response?.data?.name,
//         });
//       } else {
//         const customer_ucic = response?.data?.loan_data?.loan1?.customer_code;
//         Mixpanel.identify(
//           mobile + "-" + customer_ucic + "-" + localStorage.getItem("Device")
//         );
//         Mixpanel.track("Login", "");
//         Mixpanel.people.set({
//           $user_id: response?.data?.name,
//         });
//       }

//       if (response?.data?.message === "OTP verified") {
//         localStorage.setItem(
//           "Customer_code",
//           response?.data?.loan_data?.loan1?.customer_code ||
//             response?.data?.customer_code
//         );
//         navigate(`/arth`, {
//           state: {
//             loanData: response?.data?.loan_data,
//             mobile: mobile,
//             name: response?.data?.name,
//             email: response?.data?.email,
//             active_loans: response?.data?.active_loans,
//             transaction_amount: response?.data?.transaction_amount,
//             rewards: response?.data?.rewards,
//           },
//         });
//       } else {
//         alert("OTP verification failed. Please try again.");
//       }
//     });
//   };

//   return (
//     <>
//       <LendingPageHeader isJourneyStarted={isJourneyStarted} />
//       {showBottomPopUpBox ? (
//         <div className="pop_up">
//           <div className="bottom-pop-up-lending-page h-100">
//             {!isJourneyStarted ? (
//               <div className={`animation-box`}>
//                 <div className="welcome">
//                   <span className="hi">{displayText?.hi}</span>
//                 </div>
//                 <LanguageCard />
//                 <div className="" onClick={requestPermissions}>
//                   <ButtonFooter buttonContent={"Start"} />
//                 </div>
//                 {(searchParams.get("utm_source") || searchParams.get("redirect")) && (
//                   <>
//                     <div>
//                       <CTA mobile={""} partnerSlug={""} />
//                       <AddArthToHomeScreen />
//                     </div>
//                   </>
//                 )}
//               </div>
//             ) : (
//               <>
//                 {isOtpRequested ? (
//                   <>
//                     <OtpHandler
//                       mobile={mobile}
//                       otp={otp}
//                       setOtp={setOtp}
//                       isInputWrong={isInputWrong}
//                       inputAccepted={inputAccepted}
//                       inputMsg={inputMsg}
//                       verifyMobileOtp={verifyMobileOtp}
//                     />
//                   </>
//                 ) : (
//                   <div>
//                     <div className="otp_verification">
//                       Mobile Number Verification
//                     </div>
//                     <div className="mobile">
//                       <div className="mobile-text"></div>
//                       <div className="mobile-text-4">
//                         <input
//                           style={{
//                             border: "2px solid #999",
//                             fontSize: "16px",
//                             width: "70%",
//                             height: "40px",
//                             borderRadius: "10px",
//                             boxShadow: "0 0 5px rgba(0, 123, 255, 0.5)",
//                             textAlign: "center",
//                           }}
//                           type="text"
//                           pattern="[0-9]"
//                           className="mobile-input"
//                           value={mobile}
//                           maxLength={10}
//                           onChange={(e) => setMobile(e.target.value)}
//                           placeholder="Enter Mobile Number"
//                         />
//                       </div>
//                     </div>
//                     <div className="" onClick={getOTP}>
//                       <ButtonFooter buttonContent={"Get OTP"} />
//                     </div>
//                   </div>
//                 )}
//               </>
//             )}
//           </div>
//         </div>
//       ) : (
//         <></>
//       )}
//       {showModelInfo && (
//         <Model
//           heading={displayText?.permision_required}
//           isLendingPage={true}
//           handleClick={handleModelClick}
//         />
//       )}
//     </>
//   );
// };

// export default MobileInput;

import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const MobileInput = () => {

  useEffect(() => {
    const currentUrl = window.location.href;

    if (currentUrl.includes("/arth/login")) {
      window.location.href = "https://udyam.arthimpact.in/";
    }
  }, []);


  return (
    <></>
  )
}

export default MobileInput;