import axios, { AxiosResponse } from "axios";
import { partnerHeader } from "../base";

export class PartnerService {
  private ARTH_APP_URL: string = process.env.REACT_APP_ARTH_APP_BASE_URL;

  authenticateUserCredentials = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(`${this.ARTH_APP_URL}/auth/user/login`, payload);
  };

  logoutUser = (): Promise<AxiosResponse<any>> => {
    return axios.post(
      `${this.ARTH_APP_URL}/auth/user/logout`,
      {},
      { headers: partnerHeader() }
    );
  };

  fetchRoleOfUser = (): Promise<AxiosResponse<any>> => {
    return axios.get(`${this.ARTH_APP_URL}/auth/get_user_roles/`, {
      headers: partnerHeader(),
    });
  };

  fetchUserProfile = (): Promise<AxiosResponse<any>> => {
    return axios.get(`${this.ARTH_APP_URL}/auth/fetch_user_profile/`, {
      headers: partnerHeader(),
    });
  };

  requestMobileOtp = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(
      `${this.ARTH_APP_URL}/partners/mobile_otp_request/`,
      payload,
      { headers: partnerHeader() }
    );
  };

  verifyMobileOtp = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(
      `${this.ARTH_APP_URL}/partners/mobile_otp_verify/`,
      payload,
      { headers: partnerHeader() }
    );
  };

  requestEmailOtp = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(
      `${this.ARTH_APP_URL}/partners/email_otp_request/`,
      payload,
      { headers: partnerHeader() }
    );
  };

  verifyEmailOtp = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(
      `${this.ARTH_APP_URL}/partners/email_otp_verify/`,
      payload,
      { headers: partnerHeader() }
    );
  };

  checkDuplicay = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(
      `${this.ARTH_APP_URL}/partners/check-mobile-email-duplicacy/`,
      payload,
      { headers: partnerHeader() }
    );
  };

  requestSignUpEmailOtp = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(
      `${this.ARTH_APP_URL}/partners/signup_email_otp_request/`,
      payload
    );
  };

  verifySignupEmailOtp = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(
      `${this.ARTH_APP_URL}/partners/signup_email_otp_verify/`,
      payload
    );
  };

  requestSignupMobileOtp = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(
      `${this.ARTH_APP_URL}/partners/signup_mobile_otp_request/`,
      payload
    );
  };

  verifySignupMobileOtp = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(
      `${this.ARTH_APP_URL}/partners/signup_mobile_otp_verify/`,
      payload
    );
  };

  fetchBankName = (ifscCode: string): Promise<AxiosResponse<any>> => {
    return axios.get(
      `${this.ARTH_APP_URL}/partners/get_bank_name?bank_ifs_code=${ifscCode}`,
      { headers: partnerHeader() }
    );
  };

  fetchAccountHolderName = (
    ifscCode: string,
    accountNumber: string
  ): Promise<AxiosResponse<any>> => {
    return axios.get(
      `${this.ARTH_APP_URL}/partners/get_bank_account_holder_name?bank_ifs_code=${ifscCode}&bank_account_number=${accountNumber}`,
      { headers: partnerHeader() }
    );
  };

  submitBankDetails = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(`${this.ARTH_APP_URL}/partners/bank_details/`, payload, {
      headers: partnerHeader(),
    });
  };

  fetchBankDetails = (id: number): Promise<AxiosResponse<any>> => {
    return axios.get(
      `${this.ARTH_APP_URL}/partners/bank_details/?partner_fk=${id}`,
      { headers: partnerHeader() }
    );
  };
  fetchRetailsList = (
    search,
    filter,
    pageNo,
    id,
    funnelType
  ): Promise<AxiosResponse<any>> => {
    let url = `${this.ARTH_APP_URL}/partners/retailer-view/${id}/?`;
    if (search) {
      url += `search=${search}&`;
    }

    if (filter === "Paid") {
      url += `paid=true&`;
    }
    if (filter === "Due") {
      url += `due=true&`;
    }

    if (funnelType === "Overall") {
      url += `filter_period=all&`;
    }
    if (funnelType === "Month") {
      url += `filter_period=current_month&`;
    }
    if (funnelType === "Day") {
      url += `filter_period=today&`;
    }
    url += `page=${pageNo}&per_page=${20}`;
    return axios.get(`${url}`, { headers: partnerHeader() });
  };
  fetchRetailsListSearch = (
    search,
    filter,
    pageNo,
    id,
    funnelType
  ): Promise<AxiosResponse<any>> => {
    let url = `${this.ARTH_APP_URL}/partners/search-retailer/${id}/?`;
    if (search) {
      url += `search=${search}&`;
    }

    if (filter === "Paid") {
      url += `paid=true&`;
    }
    if (filter === "Due") {
      url += `due=true&`;
    }

    if (funnelType === "Overall") {
      url += `filter_period=all&`;
    }
    if (funnelType === "Month") {
      url += `filter_period=current_month&`;
    }
    if (funnelType === "Day") {
      url += `filter_period=today&`;
    }
    url += `page=${pageNo}&per_page=${20}`;
    return axios.get(`${url}`, { headers: partnerHeader() });
  };
  submitRetails = (payload: any, id: number): Promise<AxiosResponse<any>> => {
    return axios.post(
      `${this.ARTH_APP_URL}/partners/retailer-view/${id}/`,
      payload,
      { headers: partnerHeader() }
    );
  };
  fetchTopfiveRetailsList = (filter, id): Promise<AxiosResponse<any>> => {
    let url = `${this.ARTH_APP_URL}/partners/top-5-retailers/${id}/?`;

    if (filter === "Overall") {
      url += `filter_period=all&`;
    }
    if (filter === "Month") {
      url += `filter_period=current_month&`;
    }
    if (filter === "Day") {
      url += `filter_period=today&`;
    }
    return axios.get(`${url}`, { headers: partnerHeader() });
  };

  fetchInvoiceListByRetailers = (
    id,
    retailerbyid,
    search,
    pageNo,
    filter,
    funnelType
  ): Promise<AxiosResponse<any>> => {
    let url = `${this.ARTH_APP_URL}/partners/retailer-invoice-listing/${id}/${retailerbyid}/?`;
    if (search) {
      url += `search=${search}&`;
    }
    if (filter === "Paid") {
      url += `paid=true&`;
    }
    if (filter === "Due") {
      url += `due=true&`;
    }
    if (funnelType === "Overall") {
      url += `filter_period=all&`;
    }
    if (funnelType === "Month") {
      url += `filter_period=current_month&`;
    }
    if (funnelType === "Day") {
      url += `filter_period=today&`;
    }
    url += `page=${pageNo}&per_page=${20}`;
    return axios.get(`${url}`, { headers: partnerHeader() });
  };
  fetchGetRetailersById = (
    id: Number,
    retailerbyid: Number,
    filterData: string,
    filter: string
  ): Promise<AxiosResponse<any>> => {
    let url = `${this.ARTH_APP_URL}/partners/get-retailer/${id}/${retailerbyid}?`;
    if (filterData === "Overall") {
      url += `filter_period=all&`;
    }
    if (filterData === "Month") {
      url += `filter_period=current_month&`;
    }
    if (filterData === "Day") {
      url += `filter_period=today&`;
    }
    if (filter === "Paid") {
      url += `paid=true&`;
    }
    if (filter === "Due") {
      url += `due=true&`;
    }
    return axios.get(`${url}`, { headers: partnerHeader() });
  };
  submitSpocDetails = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(`${this.ARTH_APP_URL}/partners/spoc_details/`, payload, {
      headers: partnerHeader(),
    });
  };

  fetchDisbursalDetails = (id: Number): Promise<AxiosResponse<any>> => {
    return axios.get(
      `${this.ARTH_APP_URL}/partners/disbursal_limits/?partner_fk=${id}`,
      { headers: partnerHeader() }
    );
  };

  submitDisbursalDetails = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(
      `${this.ARTH_APP_URL}/partners/disbursal_limits/`,
      payload,
      { headers: partnerHeader() }
    );
  };

  requestSurepassOtp = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(
      `${this.ARTH_APP_URL}/partners/request-surepass-otp/E-Aadhar/`,
      payload,
      { headers: partnerHeader() }
    );
  };

  verifySurepassOtp = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(
      `${this.ARTH_APP_URL}/partners/verify-surepass-otp/E-Aadhar/`,
      payload,
      { headers: partnerHeader() }
    );
  };

  fetchDigilockerUrl = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(
      `${this.ARTH_APP_URL}/partners/get-redirect-digilocker/`,
      payload,
      { headers: partnerHeader() }
    );
  };

  updateDigilockerBrowserEvent = (
    payload: any
  ): Promise<AxiosResponse<any>> => {
    return axios.post(`${this.ARTH_APP_URL}/partners/browserevent/`, payload, {
      headers: partnerHeader(),
    });
  };

  fetchDigilockerStatus = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(
      `${this.ARTH_APP_URL}/partners/verifydigilocker/`,
      payload,
      { headers: partnerHeader() }
    );
  };

  validateKycDoc = (
    payload: any,
    type: string
  ): Promise<AxiosResponse<any>> => {
    return axios.post(
      `${this.ARTH_APP_URL}/partners/kyc-doc-verify/${type}`,
      payload,
      { headers: partnerHeader() }
    );
  };

  validateUdyamNumber = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(
      `${this.ARTH_APP_URL}/partners/kyc-number-verify/U-Aadhar`,
      payload,
      { headers: partnerHeader() }
    );
  };

  validateGSTINumber = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(
      `${this.ARTH_APP_URL}/partners/kyc-number-verify/GSTIN`,
      payload,
      { headers: partnerHeader() }
    );
  };

  validatePanNumber = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(
      `${this.ARTH_APP_URL}/partners/kyc-number-verify/PAN`,
      payload,
      { headers: partnerHeader() }
    );
  };

  fetchPartnerSpocDetails = (id: number): Promise<AxiosResponse<any>> => {
    return axios.get(
      `${this.ARTH_APP_URL}/partners/spoc_details/?partner_fk=${id}`,
      { headers: partnerHeader() }
    );
  };

  fetchPincodeDetails = (pincode: string): Promise<AxiosResponse<any>> => {
    return axios.get(
      `${this.ARTH_APP_URL}/partners/fetch_pincode_details/?pincode=${pincode}`,
      { headers: partnerHeader() }
    );
  };

  fetchPartnerList = (
    search: any,
    filter: string,
    pageNo: number
  ): Promise<AxiosResponse<any>> => {
    const payload = {
      page_num: pageNo,
      records_per_page: 20,
      filters: filter,
      search: search,
    };
    return axios.post(`${this.ARTH_APP_URL}/partners/info/all/`, payload, {
      headers: partnerHeader(),
    });
  };

  updatePartnerInfo = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(`${this.ARTH_APP_URL}/partners/info/`, payload, {
      headers: partnerHeader(),
    });
  };

  getPartnerInfo = (id: number): Promise<AxiosResponse<any>> => {
    return axios.get(`${this.ARTH_APP_URL}/partners/info/?partner_fk=${id}`, {
      headers: partnerHeader(),
    });
  };

  sendSignUpLinkToPartner = (id: number): Promise<AxiosResponse<any>> => {
    const payload = {
      partner_id: id,
    };
    return axios.post(
      `${this.ARTH_APP_URL}/partners/send_invite_link/`,
      payload,
      { headers: partnerHeader() }
    );
  };

  fetchEmailIdOnSignup = (id: number): Promise<AxiosResponse<any>> => {
    return axios.get(
      `${this.ARTH_APP_URL}/partners/fetch_email_id/?partner_id=${id}`
    );
  };

  sendSignUpOtpRequest = (email: string): Promise<AxiosResponse<any>> => {
    return axios.post(`${this.ARTH_APP_URL}/partners/signup_otp_request/`, {
      email: email,
    });
  };

  fetchDirtyField = (id: number): Promise<AxiosResponse<any>> => {
    return axios.get(
      `${this.ARTH_APP_URL}/partners/qc_remarks/?partner_fk=${id}`,
      { headers: partnerHeader() }
    );
  };

  fetchHlpLeads = (
    searchTerm: string,
    isComplete: boolean,
    partnerId: string,
    pageNo: number,
    startDate: string,
    endDate: string,
    filter_status: string
  ): Promise<AxiosResponse<any>> => {
    if (
      localStorage.getItem("currentRole") === "CentralOps" ||
      localStorage.getItem("currentRole") === "Partnership Manager"
    ) {
      return axios.get(
        `${this.ARTH_APP_URL}/partners/get-partner-leads/?is_complete=${isComplete}&search=${searchTerm}&page=${pageNo}&filter_status=${filter_status}&start_date=${startDate}&end_date=${endDate}`,
        { headers: partnerHeader() }
      );
    } else {
      return axios.get(
        `${this.ARTH_APP_URL}/partners/get-partner-leads/${partnerId}/?is_complete=${isComplete}&search=${searchTerm}&page=${pageNo}&filter_status=${filter_status}&start_date=${startDate}&end_date=${endDate}`,
        { headers: partnerHeader() }
      );
    }
  };

  hlpCafDisposition = (payload): Promise<AxiosResponse<any>> => {
    return axios.post(
      `${this.ARTH_APP_URL}/sales_app/v1/bdo_hlp_tasks/`,
      payload,
      { headers: partnerHeader() }
    );
  };

  fetchHlpLeadsnew = (
    searchTerm,
    isComplete,
    partnerId,
    pageNo,
    startDate,
    endDate,
    filter_status,
    centerList,
    productTypeValue,
    selectedOptions,
    totalperpage,
    searchtype,
    taskName,
    loanAmount,
    dpdBucket,
    disFromDate,
    disToDate,
    partnerlistData
  ): Promise<AxiosResponse<any>> => {
    //console.log("gg", taskName, loanAmount, dpdBucket, disFromDate, disToDate)
    let url1 = `${this.ARTH_APP_URL}/partners/get-partner-leads/`;
    let url = {};
    // Check if search is not null or undefined
    if (searchTerm && searchtype === "Search") {
      url["search"] = searchTerm;
    }

    // Check if active is true
    if (dpdBucket) {
      url["dpd_days"] = dpdBucket;
    }
    if (loanAmount) {
      url["loan_amount"] = loanAmount;
    }
    if (partnerlistData) {
      url["partner_name"] = partnerlistData;
    }
    if (disToDate) {
      url["dis_end_date"] = disToDate;
    }
    if (disFromDate) {
      url["dis_start_date"] = disFromDate;
    }
    if (centerList) {
      url["branch_id"] = centerList;
    }
    if (searchTerm && searchtype === "Employee Name") {
      url["employee_name"] = searchTerm;
    }
    // if(isComplete) {
    //   url['is_complete'] =isComplete;
    // }
    // if (selectedOptions) {
    //   url['status']=selectedOptions;
    // }
    if (taskName) {
      url["filter_status"] = taskName;
    }
    if (endDate) {
      url["end_date"] = endDate;
    }
    if (startDate) {
      url["start_date"] = startDate;
    }
    if (pageNo) {
      url["page"] = pageNo;
      url["is_complete"] = isComplete;
      //url['per_page'] = 20
    }
    if (
      localStorage.getItem("currentRole") === "CentralOps" ||
      localStorage.getItem("currentRole") === "Partnership Manager"
    ) {
      return axios.get(`${url1}`, { params: url, headers: partnerHeader() });
    } else {
      return axios.get(`${url1}${partnerId}`, {
        params: url,
        headers: partnerHeader(),
      });
    }
  };

  updatePartnerProfileRequests = (payload: {}): Promise<AxiosResponse<any>> => {
    return axios.post(
      `${this.ARTH_APP_URL}/partners/profile_update_requests/`,
      payload,
      { headers: partnerHeader() }
    );
  };

  fetchCustomerData = (
    mobile: string,
    partnerId: string
  ): Promise<AxiosResponse<any>> => {
    return axios.get(
      `${this.ARTH_APP_URL}/partners/customer-invoice/${partnerId}?mobile=${mobile}`,
      { headers: partnerHeader() }
    );
  };
  createPartnerInvoicebyRetailersid = (
    payload: {},
    partnerId: string
  ): Promise<AxiosResponse<any>> => {
    return axios.post(
      `${this.ARTH_APP_URL}/partners/retailer-invoice-view/${partnerId}/`,
      payload,
      { headers: partnerHeader() }
    );
  };
  updatedPartnerInvoicebyRetailersid = (
    payload: {},
    partnerId: string
  ): Promise<AxiosResponse<any>> => {
    return axios.patch(
      `${this.ARTH_APP_URL}/partners/retailer-invoice-view/${partnerId}/`,
      payload,
      { headers: partnerHeader() }
    );
  };
  createPartnerInvoice = (
    payload: {},
    partnerId: string
  ): Promise<AxiosResponse<any>> => {
    return axios.post(
      `${this.ARTH_APP_URL}/partners/customer-invoice/${partnerId}/`,
      payload,
      { headers: partnerHeader() }
    );
  };

  fetchInvoiceData = (
    invoiceId: string,
    partnerId: string
  ): Promise<AxiosResponse<any>> => {
    return axios.get(
      `${this.ARTH_APP_URL}/partners/get-invoice-details/${partnerId}/?search=${invoiceId}`,
      { headers: partnerHeader() }
    );
  };

  fetchInvoices = (
    partnerId: string,
    search: string,
    pageNo: number
  ): Promise<AxiosResponse<any>> => {
    return axios.get(
      `${this.ARTH_APP_URL}/partners/get-invoice-details/${partnerId}/?search=${search}&page=${pageNo}`,
      { headers: partnerHeader() }
    );
  };

  generateInvoicePdf = (invoiceId: string): Promise<AxiosResponse<any>> => {
    return axios.get(
      `${this.ARTH_APP_URL}/partners/generate-invoice-pdf/${invoiceId}`,
      { headers: partnerHeader() }
    );
  };
  getPartnerNameList = (): Promise<AxiosResponse<any>> => {
    return axios.get(`${this.ARTH_APP_URL}/partners/partners-listing/`, {
      headers: partnerHeader(),
    });
  };

  getLeadList = (payload): Promise<AxiosResponse<any>> => {
    return axios.get(`${this.ARTH_APP_URL}/hotleadapi/hotleadlist/`, {
      params: payload,
      headers: partnerHeader(),
    });
  };
  createCustomerLead = (payload: {}): Promise<AxiosResponse<any>> => {
    return axios.post(`${this.ARTH_APP_URL}/hotleadlist/`, payload, {
      headers: partnerHeader(),
    });
  };
  getOtp = (payload): Promise<AxiosResponse<any>> => {
    return axios.post(`${this.ARTH_APP_URL}/hotleadlist/`, payload, {
      headers: partnerHeader(),
    });
  };
  getEmailOTP = (payload): Promise<AxiosResponse<any>> => {
    return axios.post(`${this.ARTH_APP_URL}/getemailotp/`, payload, {
      headers: partnerHeader(),
    });
  };
  verifyEmailOTP = (page): Promise<AxiosResponse<any>> => {
    return axios.get(`${this.ARTH_APP_URL}/getemailotp/`, {
      headers: partnerHeader(),
    });
  };
  updateCustomerLead = (payload, id): Promise<AxiosResponse<any>> => {
    return axios.patch(`${this.ARTH_APP_URL}/getdetail/${id}/`, payload, {
      headers: partnerHeader(),
    });
  };
  getCustomerLeadDetails = (id): Promise<AxiosResponse<any>> => {
    return axios.get(`${this.ARTH_APP_URL}/getdetail/${id}/`, {
      headers: partnerHeader(),
    });
  };
  getPinCodeDetail = (payload, id): Promise<AxiosResponse<any>> => {
    return axios.patch(`${this.ARTH_APP_URL}/getdetail/${id}/`, payload, {
      headers: partnerHeader(),
    });
  };
  rejectLead = (payload): Promise<AxiosResponse<any>> => {
    return axios.post(`${this.ARTH_APP_URL}/hotleadlist/status`, payload, {
      headers: partnerHeader(),
    });
  };
  formOptions = (): Promise<AxiosResponse<any>> => {
    return axios.get(`${this.ARTH_APP_URL}/api/getcofform`, {
      headers: partnerHeader(),
    });
  };
}
