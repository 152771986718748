import { useState } from "react";
import "./styles.scss";
import ChartComponent from "./ChartComponent";
  
//   const chartOptions = {
//     scales: {
//       y: {
//         beginAtZero: true,
//         grid: {
//           display: false
//         }
//       },
//       x: {
//         beginAtZero: true,
//         grid: {
//           display: false
//         }
//       }
//     },
//     plugins: {
//       legend: {
//         display: false
//       }
//     },
//     responsive: true
//   };
  
function RiskProfile({ riskDetails }) {
  
  //console.log("riskDetails", riskDetails)
    // Transform age bracket data
    const ageData = Object.keys(riskDetails?.age_bracket_averages)
      .filter(key => riskDetails.age_bracket_averages[key] !== null)
      .map((key, index) => ({
        x: key, // Example X position
        y: riskDetails.age_bracket_averages[key]
      }));
  
    // Transform income bracket data
    const incomeData = Object.keys(riskDetails?.income_bracket_averages)
      .filter(key => riskDetails.income_bracket_averages[key] !== null)
      .map((key, index) => ({
        x: key, // Example X position
        y: riskDetails.income_bracket_averages[key]
      }));
  
    // Transform business occupation data
    const businessData = Object.keys(riskDetails?.business_occupation_averages)
      .map((key, index) => ({
        x: key, // Example X position
        y: riskDetails.business_occupation_averages[key]
      }));
  
      const ageriskData = {
        datasets: [
          {
            data: ageData,
            borderColor: '#EE3E80',
            borderWidth: 1,
            pointRadius: 2,
            pointHoverRadius: 3,
            fill: false,
            tension: 0,
            showLine: true
          },
          {
            data:ageData,
            pointBackgroundColor: '#EE3E80',
            pointBorderColor: 'darkorange',
            pointRadius: 2,
            pointHoverRadius: 4
          }
        ]
      };
      const IncomeRiskData = {
        datasets: [
          {
            data: incomeData,
            borderColor: '#EE3E80',
            borderWidth: 1,
            pointRadius: 2,
            pointHoverRadius: 3,
            fill: false,
            tension: 0,
            showLine: true
          },
          {
            data: incomeData,
            pointBackgroundColor: '#EE3E80',
            pointBorderColor: 'darkorange',
            pointRadius: 2,
            pointHoverRadius: 4
          }
        ]
      };
      const occupationRiskData = {
        datasets: [
          {
            data: businessData,
            borderColor: '#EE3E80',
            borderWidth: 1,
            pointRadius: 2,
            pointHoverRadius: 3,
            fill: false,
            tension: 0,
            showLine: true
          },
          {
            data: businessData,
            pointBackgroundColor: '#EE3E80',
            pointBorderColor: 'darkorange',
            pointRadius: 2,
            pointHoverRadius: 4
          }
        ]
      };
      const chartOptions = (labels) => ({
        scales: {
          y: {
            beginAtZero: true,
            grid: {
              display: false
            }
          },
          x: {
            beginAtZero: true,
            grid: {
              display: false
            },
            type: 'category',
            labels: labels
          }
        },
        plugins: {
          legend: {
            display: false
          },
          animation: false,
        },
        animation: {
          duration: 0, // general animation time
      },
      hover: {
          animationDuration: 0, // duration of animations when hovering an item
      },
      responsiveAnimationDuration: 0,
        responsive: true
      });
  return (
    <div className="riskProfile-container" style={{ width:'100%'}}>
      {/* <span className="kyc-header">Risk Profile</span> */}
      <div className="">
        <div className="subheading-risk-chart" style={{marginTop:'1rem'}}>Risk Profile</div>
        <div className="details-riskProfile" style={{marginTop:"1rem", height:"250px", width:'100%'}}>
          <div style={{ width: '33%' }}>
            <div className="heading-risk-chart">Occupation</div>
            <div className="chartoccuptaion">
              <ChartComponent  chartId="3" data={occupationRiskData} options={chartOptions(Object.keys(riskDetails.business_occupation_averages))} />
            </div>
          </div>
          <div style={{ width: '33%' }}>
            <div className="heading-risk-chart">Income</div>
            <div className="chartoccuptaion">
              <ChartComponent chartId="4" data={IncomeRiskData} options={chartOptions(Object.keys(riskDetails.income_bracket_averages))} />
            </div>
          </div>
          <div style={{ width: '33%' }}>
            <div className="heading-risk-chart">Age</div>
            <div className="chartoccuptaion">
              <ChartComponent chartId="5" data={ageriskData} options={chartOptions(Object.keys(riskDetails.age_bracket_averages))} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RiskProfile;
