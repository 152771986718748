import React, { useEffect } from "react";
import { useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "./business-slider.scss";
import { Modal } from "react-bootstrap";
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';

function BusinessSlider({ sliderImages }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [activeImageNum, setCurrent] = useState(0);
  const [imageRotation, setImageRotation] = useState({});
  const length = sliderImages.length;

  const handleRotation = (rotationValue) => {
    if (imageRotation[activeImageNum]) {
      imageRotation[activeImageNum] =
        imageRotation[activeImageNum] +
        (rotationValue === "reverse" ? -90 : 90);
    } else {
      imageRotation[activeImageNum] = rotationValue === "reverse" ? -90 : 90;
    }

    setImageRotation({ ...imageRotation });
  };

  const nextSlide = () => {
    setCurrent(activeImageNum === length - 1 ? 0 : activeImageNum + 1);
  };
  const prevSlide = () => {
    setCurrent(activeImageNum === 0 ? length - 1 : activeImageNum - 1);
  };
  if (!Array.isArray(sliderImages) || sliderImages.length <= 0) {
    return null;
  }
  return (
    <div class="container">
      <section className="image-slider">
        <div class="col-md-2">
          <div class="left-slider-btn">
            <ArrowBackIosIcon onClick={prevSlide} />
          </div>
        </div>

        <div class="col-md">
          {sliderImages.map((currentSlide, ind) => {
            return (
              <div
                className={
                  ind === activeImageNum
                    ? "currentSlide active"
                    : "currentSlide"
                }
                key={ind}
              >
                {ind === activeImageNum && (
                  <img
                    src={currentSlide}
                    className="image"
                    alt=""
                    onClick={() => handleShow()}
                    style={{
                      cursor: "pointer",
                      transform: imageRotation[ind]
                        ? "rotate(" + imageRotation[ind] + "deg)"
                        : "rotate(0deg)",
                    }}
                  />
                )}
              </div>
            );
          })}
        </div>
        <div class="col-md-2">
          <div class="right-slider-btn">
            <ArrowForwardIosIcon onClick={nextSlide} />
          </div>
        </div>
        <Modal
          show={show}
          onHide={handleClose}
          style={{
            height: "100%",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            transform: imageRotation[activeImageNum]
              ? "rotate(" + imageRotation[activeImageNum] + "deg)"
              : "rotate(0deg)",
          }}
        >
          <img
            src={sliderImages[activeImageNum]}
            alt=""
            style={{ height: "100%", width: "100%" }}
            onClick={() => handleShow()}
          />
        </Modal>
      </section>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{ cursor: "pointer" }}
          onClick={() => handleRotation("reverse")}
        >
          <RotateLeftIcon />
        </div>
        <div
          style={{ cursor: "pointer" }}
          onClick={() => handleRotation("forward")}
        >
          <RotateRightIcon />
        </div>
      </div>
    </div>
  );
}

export default BusinessSlider;
