import { useEffect, useState } from "react";
import "./text-input.scss";
import ActionButton from "../button/ActionButton";
import { GreenCheck2 } from "../../../assets/svg";

const TextInput = (props) => {
  const {
    heading,
    isCompulsoryField,
    preInputText,
    placeholder,
    value,
    handleInput,
    handleActionButton,
    actionButtonStatus,
    identifier,
    supportiveTextRed,
    supportiveTextGreen,
    showActionButton = false,
    actionButtonContent,
    actionSuccess,
    disabled = false,
    maxLength,
    minLength,
    inputMode = "text",
    type = "text",
    reKycRemarks,
    requireInput = true,
    emailEmailOTPVerified,
    isEmailOTPSent,
    requestEmailOTP,
  } = props;

  const [userInput, setUserInput] = useState("");

  const emmitValue = (e) => {
    setUserInput(e.target.value);
    handleInput(identifier, e.target.value);
  };

  const actionButtonClicked = () => {
    if (actionButtonStatus) {
      handleActionButton();
    }
  };

  const renderActionButton = () => {
    if (value?.includes("@") && !emailEmailOTPVerified && !isEmailOTPSent) {
      return (
        <div onClick={actionButtonClicked} className="action-button-style">
          <ActionButton
            isActive={actionButtonStatus}
            buttonContent={actionButtonContent}
          />
        </div>
      );
    }
    return null;
  };


  return (
    <div className="partner-text-input">
      <div className="partner-heading" style={{ paddingBottom: "5px" }}>
        {heading}
        <span>&nbsp;{isCompulsoryField && "*"}</span>
      </div>
      {(identifier === "email" || identifier === "email_id") && showActionButton ? (
        <>
          <div className="input-group">
            {preInputText && (
              <span className="input-group-text" id="basic-addon1">
                {preInputText}
              </span>
            )}
            <input
              type={type}
              className={`form-control ${supportiveTextRed && "red-border"}`}
              placeholder={placeholder}
              aria-label="Email"
              aria-describedby="basic-addon1"
              value={value}
              disabled={disabled}
              onChange={(e) => emmitValue(e)}
              maxLength={maxLength}
              minLength={minLength}
              inputMode={inputMode}
              required={requireInput}
            />

            {renderActionButton()}
            {actionSuccess && (
              <div className="action-button-style">
                <GreenCheck2 /> Verified
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          <div className="input-group">
            {preInputText && (
              <span className="input-group-text" id="basic-addon1">
                {preInputText}
              </span>
            )}
            <input
              type={type}
              className={`form-control ${supportiveTextRed && "red-border"}`}
              placeholder={placeholder}
              aria-label="Username"
              aria-describedby="basic-addon1"
              value={value}
              disabled={disabled}
              onChange={(e) => emmitValue(e)}
              maxLength={maxLength}
              minLength={minLength}
              inputMode={inputMode}
              required={requireInput}
            />
            {(showActionButton || actionSuccess) && (
              <div
                onClick={actionButtonClicked}
                className="action-button-style"
              >
                {actionSuccess ? (
                  <>
                    <GreenCheck2 />
                    Verified
                  </>
                ) : (
                  <ActionButton
                    isActive={actionButtonStatus}
                    buttonContent={actionButtonContent}
                  />
                )}
              </div>
            )}
          </div>
        </>
      )}
      <div className="partner-supportive-text-red">{supportiveTextRed}</div>
      <div className="partner-supportive-text-green">{supportiveTextGreen}</div>
      {reKycRemarks?.map((remark, index) => {
        return (
          <div key={index} className="partner-supportive-text-red">
            {remark}
          </div>
        );
      })}
    </div>
  );
};

export default TextInput;
