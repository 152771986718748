import { useState } from "react";
import "./kyc.scss";
import ImageSlider from "../ImageSlider/ImageSlider";
import { Col, Row } from "react-bootstrap";

function KYC({ userData }) {
  let kycResponse;
  if (userData?.aadhaar_details) {
    kycResponse = {
      "Aadhar Details": {
        Name: userData?.aadhaar_details?.name,
        Gender: userData?.aadhaar_details?.gender,
        "D.O.B": userData?.aadhaar_details?.dob,
        "Aadhar No.": userData?.aadhaar_details?.aadhaar_number,
        Address: userData?.aadhaar_details?.address,
      },
    };
  }

  return (
    <div className="kyc-container">
      <span className="kyc-header">KYC DETAILS</span>
      <div
        style={{
          display: "flex",
          padding: "10px",
          gap: "10px",
        }}
      >
        <div class="col-md kyc-container">
          <span>Aadhar Details</span>

          <div className="col-md">
            <div className="kyc-margin">
              {userData?.aadhaar_details?.kyc_type == "Aadhaar Card" ? (
                <div class="container">
                  <ImageSlider
                    kyc_details={userData?.customer_details?.kyc_details}
                  />
                </div>
              ) : (
                 kycResponse?.["Aadhar Details"] &&
                <Row style={{ marginTop: "20px" }}>
                  <div className="col-md-auto">
                    <img
                      src={userData?.shop_owner_photo}
                      style={{
                        width: "175px",
                        height: "175px",
                        borderRadius: "14px",
                      }}
                      alt=""
                    />
                  </div>
                  <div class="col-md">
                    <div style={{ display: "flex", cursor: "pointer" }}>
                      <div class="col-md" className="kyc-content">
                        {Object.keys(kycResponse?.["Aadhar Details"]).map(
                          (key) => (
                            <div class="row">
                              <div className="col-md-auto basic-left-body kyc-font kyc-key">
                                {key}
                              </div>
                              <div
                                class={`col-md basic-right-body kyc-font kyc-value`}
                              >
                                {kycResponse["Aadhar Details"][key]}
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </Row>
              )}
            </div>
          </div>
        </div>
        <div class="col-md kyc-container">
          <span>Pan Details</span>
          <div className="kyc-margin">
            <div>
              <div class="col-md-auto">
                <div class="row">
                  <div class="col-md-auto basic-left-body kyc-font kyc-key">
                    PAN No.
                  </div>
                  <div class={`col-md basic-right-body kyc-font kyc-value`}>
                    {
                      userData?.customer_details?.pan_details
                        ?.pan_no
                    }
                     <div style={{fontFamily:"Montserrat", color:"#10B759", marginTop:'0.6rem', fontWeight:500, fontSize:12, lineHeight:"14.63px"
                  }}>
                   {/* {userData?.customer_details?.Demographics?.name} */}
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default KYC;
