import { useDispatch, useSelector } from "react-redux";
import JourneyHeader from "../../../components/customer-application/headers/JourneyHeader";
import ButtonFooter from "../../../components/customer-application/button-footer/ButtonFooter";
import "./kfs-third-screen.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { LoaderService } from "../../../utils/utilities/loaderService";
import { useEffect, useState } from "react";
import { ApiService } from "../../../services/api/apiService";
import { currentAction } from "../../../store/actions/customer-application/offerAction";
import { LocalStorage } from "../../../utils/constants/localStorage";

const KFSThirdScreen = () => {
  const apiService = new ApiService();
  const displayText = useSelector((state: any) => state.language.languageText);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const mobile = localStorage.getItem(LocalStorage.MOBILE);
  const loaderService = new LoaderService();
  const [lender, setLender] = useState<any>();
  const [leaderAgreementLink,setLeaderAgreementLink] = useState<any>(null);
  const [lat, setLat] = useState<any>();
  const [long, setLong] = useState<any>();
  const { partnerSlug } = useParams();
  const [insurance_Validity, setInsuranceValidity] = useState<boolean>(false);
  const location = useLocation();
  // const [repaymentTable, setRepaymentTable] = useState([]);

  const { loanAmount, tranferToBankAccount, loanDetails } =
    location.state || {};
  const selectedIds = JSON.parse(localStorage.getItem("selectedIds") || "[]");

  useEffect(() => {
    getLatLong();
    getLenders();
    // insuranceValidity();
  }, []);

  useEffect(() => {
    if (localStorage.getItem("EDI") === "False") {
      localStorage.setItem("EDI", "False");
    } else {
      localStorage.setItem("EDI", "True");
    }
  }, [localStorage.getItem("EDI")]);
  useEffect(() => {
    lenderAgreement()
  }, []);
  const lenderAgreement = () => {
    apiService.getFetchAgreement(loanDetails?.loan_uid).then((res: any) => {
      if(res?.status === 200){
        setLeaderAgreementLink(res?.data?.tnc_link)
      }
    }).catch((err) => err);
  }
  const multiLenders = {
    name: lender?.name,
    product_code: lender?.product_code,
    tnc_contract_code: lender?.tnc_contract_code,
  };

  const getLenders = () => {
    apiService
      .getLenders(loanDetails?.loan_uid)
      .then((response: any) => {
        setLender(response.data.lenders);
      })
      .catch((error: any) => {
        loaderService.errorService(error);
      });
  };

  const lenderTermsAndCondition = () => {
    if (leaderAgreementLink) {
      window.open(leaderAgreementLink, "_blank");
    }
  };

  const insurenceTermsAndCondition = () => {
    const insuranceConsentLink =
      "https://arth-banner.s3.ap-south-1.amazonaws.com/HDFC-Life-Group-Jeevan-Suraksha-R+(1).pdf";
    window.open(insuranceConsentLink, "_blank");
  };

  const businessDocuments = () => {
    const businessDocumentLink =
      "https://arth-banner.s3.ap-south-1.amazonaws.com/Business+Prime+Agreement_v1_250724+(1).pdf";
    window.open(businessDocumentLink, "_blank");
  };

  const getLatLong = () => {
    navigator.permissions
      .query({
        name: "geolocation",
      })
      .then(function (result) {
        navigator.geolocation.getCurrentPosition(
          function (position) {
            setLat(position.coords.latitude.toString());
            setLong(position.coords.longitude.toString());
          },
          function (error) {
            alert("Please allow location");
          }
        );
        if (result.state === "granted") {
        } else if (result.state === "prompt") {
        } else if (result.state === "denied") {
        }
      });
  };

  const insuranceValidity = () => {
    apiService.getTnCDocuments(loanDetails?.customer_code).then((response) => {
      if (response?.data?.result) {
        response?.data?.result.map((value) => {
          if (
            value["validity"] === true &&
            value["document_type"] === "insurance"
          ) {
            setInsuranceValidity(true);
          }
        });
      }
    });
  };

  const acceptTermsAndCondition = () => {
    loaderService.showLoader();
    const selectedIds = JSON.parse(localStorage.getItem("selectedIds") || "[]");
    const tncPayload = {
      t_tnc_accepted: new Date().getTime() / 1000,
      t_deduct_funds_consented: new Date().getTime() / 1000,
      t_whatsapp_consented: new Date().getTime() / 1000,
      payment: {},
      acceptance_for_multi_lenders: multiLenders,
      lat: lat,
      lon: long,
      addon_ids: selectedIds || [],
      // business_prime_opted: businessPrimeSelected === true ? true : false || "",
      // documents: [
      //   businessPrimeSelected === true ? "business_prime" : "",
      //   insurance_Validity === true ? "" : "insurance",
      // ],
      // accepted_amount:
      //   loanDetails?.partner_has_bp === true
      //     ? parseInt(loanAmount)
      //     : parseInt(loanDetails?.loan_amount),
      // security_amount: 0,
      // recovery_type:
      //   localStorage.getItem("EDI") === "True"
      //     ? "recovery_by_edi"
      //     : "recovery_in_installments",
    };
    if (lat && long) {
      apiService
        .acceptLoanTermsAndCondition(tncPayload)
        .then((response: any) => {
          loaderService.hideLoader();
          dispatch(currentAction(response.data.current_action));
          navigate(
            `/${partnerSlug}/session/${mobile}/${response.data.current_action}`
          );
          localStorage.removeItem("EDI");
          localStorage.removeItem("BP");
        })
        .catch((error: any) => {
          loaderService.hideLoader();
          loaderService.errorService(error);
        });
    } else {
      getLatLong();
    }
  };
  function formatNumberToIndianStyle(num) {
    const numString = num.toString();
    const lastThreeDigits = numString.slice(-3);
    const otherDigits = numString.slice(0, -3);

    if (otherDigits) {
      return `${otherDigits.replace(
        /\B(?=(\d{2})+(?!\d))/g,
        ","
      )},${lastThreeDigits}`;
    }

    return lastThreeDigits;
  }
  return (
    <>
      <JourneyHeader heading={displayText?.loan_details} />
      <div className="loan-details-page">
        <div className="loan-amount">
          <div className="amount">
            ₹ {formatNumberToIndianStyle(parseInt(loanDetails?.loan_amount))}
          </div>
          <div className="text">{displayText?.loan_amount}</div>
        </div>
        <div
          style={{
            fontSize: "0.75rem",
            display: "grid",
            alignItems: "center",
            marginTop: "5%",
            gridTemplateColumns: "1fr auto",
          }}
        >
          <div>
            <h6 style={{ fontSize: "0.95rem", margin: 0 }}>
              {displayText?.transfer_to_bank}
            </h6>
          </div>
          <div
            style={{
              fontSize: "0.95rem",
              fontWeight: "600",
              textAlign: "right",
            }}
          >
            ₹{" "}
            {tranferToBankAccount ||
              formatNumberToIndianStyle(loanDetails?.net_disbursal_amount)}
          </div>
        </div>
        <div className="button-footer-2">
          <button
            type="button"
            className="button-footer-4"
            onClick={() =>
              navigate(
                "/accept_tnc/repayament_schedule/" + partnerSlug + "/" + mobile,
                {
                  state: {
                    loanAmount: loanAmount,
                    loanDetails: loanDetails,
                    transferAmount: loanDetails?.loan_amount,
                    tranferToBankAccount: tranferToBankAccount,
                  },
                }
              )
            }
          >
            <p className="button-font-size">View Repayment Schedule</p>
          </button>
        </div>
        <div className="t-c">
          <div></div>
          <div className="heading-1">
            {displayText?.by_clicking}{" "}
            <span
            // style={{ color: "#ee3e80" }}
            // onClick={lenderTermsAndCondition}
            >
              {displayText?.lending_page_tnc_msg_2}
            </span>{" "}
            <span
              style={{ color: "#ee3e80", cursor: "pointer" }}
              onClick={lenderTermsAndCondition}
            >
              {displayText?.lending_page_tnc_ms_4}
            </span>{" "}
            ,{" "}
            <span
              style={{ color: "#ee3e80", cursor: "pointer" }}
              onClick={insurenceTermsAndCondition}
            >
              {displayText?.lending_page_tnc_ms_5}
            </span>
          </div>
        </div>
        {/* {businessPrimeSelected === true && ( */}
        {false && (
          <>
            <div className="t-c-b">
              <div></div>
              <div className="heading-1">
                {displayText?.by_clicking}{" "}
                <span style={{ color: "#ee3e80" }} onClick={businessDocuments}>
                  Business Prime Benefits
                </span>
              </div>
            </div>
          </>
        )}
        <div className="loan-details-button1" onClick={acceptTermsAndCondition}>
          <ButtonFooter buttonContent={displayText?.i_accept} />
        </div>
      </div>
    </>
  );
};

export default KFSThirdScreen;
